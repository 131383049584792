import { Alert, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { addDoc, collection, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MKBox from '../../../../components/MKBox';

const db = getFirestore();
const auth = getAuth();

function CommunityForum() {
  const [threads, setThreads] = useState([]);
  const [newThreadTitle, setNewThreadTitle] = useState('');
  const [newThreadBody, setNewThreadBody] = useState('');
  const [openCreateThreadDialog, setOpenCreateThreadDialog] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is authenticated
    const user = auth.currentUser;
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    // Fetch threads from Firestore
    const fetchThreads = async () => {
      const q = query(collection(db, 'threads'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const fetchedThreads = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setThreads(fetchedThreads);
    };

    fetchThreads();
  }, []);

  const handleCreateThread = async () => {
    if (!newThreadTitle || !newThreadBody) {
      alert('Both fields are required');
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/sign-in'); // Redirect if the user is not authenticated
        return;
      }

      // Add the new thread to Firestore
      await addDoc(collection(db, 'threads'), {
        title: newThreadTitle,
        body: newThreadBody,
        createdAt: new Date(),
        createdBy: user.uid,
        createdByName: user.username || 'Anonymous',
      });

      // Reset form fields
      setNewThreadTitle('');
      setNewThreadBody('');
      setOpenCreateThreadDialog(false);

      // Show the Snackbar with a success message
      setSnackbarMessage('Thread created successfully!');
      setOpenSnackbar(true);

      // Re-fetch threads after creating a new thread
      const q = query(collection(db, 'threads'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const fetchedThreads = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setThreads(fetchedThreads);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container>
      <MKBox mt={20} sx={{ textAlign: 'center' }}>
        <Typography variant="h3" gutterBottom>
          Community Threads
        </Typography>

        <Typography variant="body1" color="textSecondary" paragraph>
          Connect, share your ideas, and discuss with others in our community.
        </Typography>

        {isAuthenticated ? (
  <Button className="ef-sm-btn" onClick={() => setOpenCreateThreadDialog(true)}>
    Create a New Thread
  </Button>
) : (
  <Button className="ef-sm-btn" onClick={() => navigate('/sign-in')}>
    You Must Sign In To Create a New Thread
  </Button>
)}

        <Grid container spacing={3} mt={5}>
          {threads.length > 0 ? (
            threads.map(thread => (
              <Grid item xs={12} md={6} key={thread.id}>
                <Card sx={{ padding: 2 }}>
                  <Typography variant="h5">{thread.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {thread.createdByName} - {new Date(thread.createdAt.seconds * 1000).toLocaleString()}
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    {thread.body}
                  </Typography>

                  <Link to={`/threads/${thread.id}`}>View Responses</Link>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" color="textSecondary">
                No threads available. Be the first to start a conversation!
              </Typography>
            </Grid>
          )}
        </Grid>
      </MKBox>

      {/* Create Thread Dialog */}
      <Dialog open={openCreateThreadDialog} onClose={() => setOpenCreateThreadDialog(false)}>
        <DialogTitle>Create a New Thread</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Thread Title"
            fullWidth
            value={newThreadTitle}
            onChange={(e) => setNewThreadTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Thread Body"
            fullWidth
            multiline
            rows={4}
            value={newThreadBody}
            onChange={(e) => setNewThreadBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateThreadDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateThread} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for thread creation success */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000} // 3 seconds
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CommunityForum;
