import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MKBox from "../../../../components/MKBox";
import { auth } from "../../../../firebase";
import ManageAvailability from "./Availability";
import VendorBookingManagement from "./BookingRequest";

function BookingManagement() {
  const { city, vendorId } = useParams();  // Extract city and vendorId parameters from URL
  const navigate = useNavigate(); // To navigate if city or vendorId is not found
  const [loading, setLoading] = useState(false);
  const [userCity, setUserCity] = useState('');
  



  return (
    <>
      <MKBox >
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundColor: '#ff8a41',
            backgroundSize: "cover",
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.1),
                rgba(gradients.dark.state, 0.8)
              )}`,
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            opacity: ".9"
          }}
        >
          <Grid item xs={12}>
            <h1
              className="ef-title"
              style={{
                color: "white",
                fontSize: "2.5em"
              }}
            >
              Booking Management
            </h1>
          </Grid>
        </MKBox>

        <Grid container>
          <ManageAvailability vendorId={auth.currentUser?.uid} />
          <VendorBookingManagement vendorId={auth.currentUser?.uid} />
        </Grid>
      </MKBox>
    </>
  );
}

export default BookingManagement;
