import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { addDoc, collection, getDocs } from "firebase/firestore";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import headerImage from "../../../../../assets/images/2025eventflowbg.png";
import RotatingCard from "../../../../../examples/Cards/RotatingCard";
import RotatingCardBack from "../../../../../examples/Cards/RotatingCard/RotatingCardBack";
import RotatingCardFront from "../../../../../examples/Cards/RotatingCard/RotatingCardFront";
import { firestore } from "../../../../../firebase";

// Function to convert 12-hour time format (AM/PM) to 24-hour (military) format
const convertToMilitaryTime = (time12hr) => {
  const [time, modifier] = time12hr.split(" ");  // Split time and AM/PM
  let [hours, minutes] = time.split(":");  // Split hours and minutes

  if (modifier === "PM" && hours !== "12") {
    hours = (parseInt(hours) + 12).toString();  // Convert PM hour to 24-hour format
  }
  if (modifier === "AM" && hours === "12") {
    hours = "00";  // Midnight case: 12 AM is 00 in 24-hour format
  }

  return `${hours}:${minutes}`;
};

// Function to convert 24-hour time to 12-hour format (AM/PM)
const convertTo12HourFormat = (militaryTime) => {
  let [hours, minutes] = militaryTime.split(":");
  const modifier = hours >= 12 ? "PM" : "AM";
  if (hours > 12) hours -= 12;
  if (hours === "0") hours = "12";  // Midnight case: 00:xx -> 12:xx AM

  return `${hours}:${minutes} ${modifier}`;
};

const Itinerary = ({ vendors, owner, event_date, eventId }) => {
  const [itinerary, setItinerary] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventDate, setEventDate] = useState(event_date || "");  // Fix for event_date error
  const [customVendor, setCustomVendor] = useState("");  
  const [useCustomVendor, setUseCustomVendor] = useState(false);  

  // Fetch itinerary from Firestore when component mounts
  useEffect(() => {
    console.log('my id for event ', eventId);
    const fetchItinerary = async () => {
      if (eventId) {
        // Fetch the itinerary for a specific event
        const eventRef = collection(firestore,  "Events", eventId, "Itinerary");
        const snapshot = await getDocs(eventRef);
        const itineraryData = snapshot.docs.map(doc => doc.data());
        setItinerary(itineraryData);
      }
    };

    fetchItinerary();
  }, [eventId]);

  // Function to open modal
  const handleOpenModal = () => setOpenModal(true);

  // Function to close modal
  const handleCloseModal = () => setOpenModal(false);

  // Function to add vendor to itinerary
  const handleAddVendorToItinerary = async () => {
    if ((!selectedVendor && !useCustomVendor) || !eventTime) {
      alert("Please select or enter a vendor and time!");
      return;
    }

    // Convert the selected time to military time (24-hour format)
    const militaryTime = convertToMilitaryTime(eventTime);

    // Determine the vendor name (custom or selected)
    const vendorName = useCustomVendor ? customVendor : selectedVendor;

    // Find the selected vendor or use custom vendor details
    const vendor = vendors.find((v) => v.vendor_name === vendorName) || { vendor_name: vendorName, vendor_pictureUrl: "" };

    // Create the new itinerary item
    const newItem = {
      vendor: vendor.vendor_name,
      image: vendor.vendor_pictureUrl || headerImage,  // Use custom or default image
      time: militaryTime,  // Use the converted military time
      date: event_date,
    };

    // Update local state
    setItinerary((prevItinerary) => {
      const updatedItinerary = [...prevItinerary, newItem];

      // Sort the updated itinerary by time in ascending order
      updatedItinerary.sort((a, b) => a.time.localeCompare(b.time));

      return updatedItinerary;
    });

    // Save the new item to Firestore
    try {
      if (eventId) {
        const itineraryRef = collection(
          firestore,
          "Events", 
          eventId, 
          "Itinerary"  // Save under the "Itinerary" sub-collection
        );

        // Add the new itinerary item to Firestore
        await addDoc(itineraryRef, newItem);  
        console.log("Itinerary item added to Firestore");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    // Close the modal after adding the vendor
    handleCloseModal();
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{ textAlign: "center", padding: 3 }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
          }}
        >
          Agenda
        </Typography>
      </Grid>

      {/* Render Itinerary Cards */}
      <Grid container item spacing={3} justifyContent="center" xs={12}>
        {itinerary.map((item, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <RotatingCard sx={{ height: "60vh" }}>
              <RotatingCardFront
                image={item.image}
                icon="checklist"
                description={item.vendor}
              />
              <RotatingCardBack
                image={headerImage}
                title={convertTo12HourFormat(item.time)}  // Display in 12-hour format
                description={item.date}
                action={{
                  type: "internal",
                  route: `/edititinerary/${eventId}/${item.id}`,  // Add itemId to the route
                  label: "",
                }}
              />
            </RotatingCard>
          </Grid>
        ))}
        <br />
        {owner && (
          <>
            {/* Button to open the modal for adding vendor */}
            <Grid item xs={12} sm={12} md={12}>
              <Button className="ef-sm-btn" onClick={handleOpenModal}>
                Add 
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      {/* Modal for Adding Vendor */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Add to Itinerary</DialogTitle>
        <DialogContent>
          {/* Vendor Selection */}
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Vendor</InputLabel>
            <Select
              value={selectedVendor}
              onChange={(e) => setSelectedVendor(e.target.value)}
              label="Vendor"
              disabled={useCustomVendor}  // Disable dropdown if custom vendor is selected
            >
              {vendors.map((vendor) => (
                <MenuItem key={vendor.vendor_id} value={vendor.vendor_name}>
                  {vendor.vendor_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Custom Vendor Option */}
          <FormControlLabel
            control={
              <Checkbox
                checked={useCustomVendor}
                onChange={(e) => setUseCustomVendor(e.target.checked)}
                color="primary"
              />
            }
            label="Use Custom"
          />

          {useCustomVendor && (
            <TextField
              label="Custom Item Name"
              value={customVendor}
              onChange={(e) => setCustomVendor(e.target.value)}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          )}

          {/* Time Selection */}
          <TextField
            label="Event Time"
            type="time"
            value={eventTime}
            onChange={(e) => setEventTime(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            inputProps={{
              step: 300, // 5 minutes interval
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddVendorToItinerary} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

// Add Prop Types for validation
Itinerary.propTypes = {
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      vendor_id: PropTypes.string.isRequired,
      vendor_name: PropTypes.string.isRequired,
      vendor_pictureUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  owner: PropTypes.bool,  // owner should be a boolean
  event_date: PropTypes.string,  // event_date should be a string
  eventId: PropTypes.string.isRequired,  // eventId is required for the itinerary
};

export default Itinerary;
