import { Person, Phone, Web } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { FC, useState } from 'react';
import { FaBuilding } from "react-icons/fa";
//  TODO: Add conditional to show file or itemized list


interface ProfileProps {
  id: string;
  address: any;
  phone: string;
  name: string;
  web: string;
}



const ContactVendor: FC<ProfileProps> = ({ id, address, phone, name, web }) => {

  const [vendor, setVenue] = useState<any>();
  const [value, setValue] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(-1);
  const [loading, setLoading] = useState(true);

  return (
    <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
    <Box>
      <h3>Contact</h3>
      <hr></hr>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>
      <Typography id='contactinfo'>
       <Person/> {name}<br></br>
  <FaBuilding/> {address} <br></br>
       <Phone/> {phone}<br></br>
       <Web/> <a 
  className="web" 
  href={web.startsWith('https://') ? web : `https://${web}`} 
  target="_blank" 
  rel="noopener noreferrer"
>
  {web}
</a>      </Typography>
    </Grid>
      </Box>
    </Grid>
 
  );
          }


export default ContactVendor;