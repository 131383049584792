import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { browserLocalPersistence, onAuthStateChanged, setPersistence } from "firebase/auth";
import { doc, getDoc, query } from "firebase/firestore";
import Cookies from 'js-cookie';
import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import theme from "./assets/theme";
import './ef-styles.css';
import DefaultNavbar from "./examples/Navbars/DefaultNavbar";
import { auth, firestore, logout } from "./firebase"; // Import auth and firestore from firebase setup
import Presentation from "./layouts/pages/presentation";
import ThreadDetail from "./pages/Community/Forum/components/ThreadDetail";
import AboutUs from "./pages/LandingPages/AboutUs";
import LogOut from "./pages/logout";
import CartPage from "./pages/Users/Cart";
import { CartProvider } from "./pages/Users/Cart/CartContext";
import CheckInPage from "./pages/Users/Events/CheckIn";
import EventDashboard from "./pages/Users/Events/Dashboard";
import EventDetails from "./pages/Users/Events/EventDetails";
import EditItineraryItem from "./pages/Users/Events/EventDetails/components/EditItineraryItem";
import RegisterUser from "./pages/Users/Register";
import UserSignIn from "./pages/Users/SignIn";
import Vendor from "./pages/Vendors";
import BookingManagement from "./pages/Vendors/Booking/Management";
import AdminDashboard from "./pages/Vendors/Dashboard";
import InventoryPage from "./pages/Vendors/Inventory";
import AddInventoryPage from "./pages/Vendors/Inventory/AddInventory";
import VendorSettings from "./pages/Vendors/Settings";
import VendorPage from "./pages/Vendors/VendorPage";
import { routes, vendorRoutes } from "./routes";


export default function App() {
  const { pathname } = useLocation();
  const [status, setStatus] = useState(false);
  const [globalDownloadURL, setglobalDownloadURL] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [isVendor, setIsVendor] = useState(false); // New state for isVendor flag
  const [cookieConsent, setCookieConsent] = useState(false);


  /*
  useEffect(() => {


    // Firebase Auth State Listener
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setStatus(true);
        
        console.log(user.uid);

        // Fetch the `isVendor` flag from Firestore
        const userDocRef = doc(firestore, 'users', user.uid);
        const q = query(userDocRef);

        

        const userDoc = await getDoc(q);
        if (userDoc.exists) {
          const userData = userDoc.data();
          setIsVendor(userData.isVendor === 1); // Set `isVendor` based on the flag
        }
      } else {
        setStatus(false);
        setIsVendor(false); // Reset vendor flag when logged out
      }
    });
    return () => unsubscribe();
  }, []);


  */


  useEffect(() => {
    // Check if the cookieConsent cookie is set
    const consent = Cookies.get("userCookieConsent");
    if (consent) {
      setCookieConsent(true);
    }
  }, []);

  useEffect(() => {
    const userFromCookie = Cookies.get('user');
    if (userFromCookie) {
      // If the cookie exists, parse the user and set it in your app state
      const user = JSON.parse(userFromCookie);
      console.log("User from cookie:", user);
    } else {
      console.log("No user cookie found");
    }
  }, []);

useEffect(() => {
  document.documentElement.scrollTop = 0;
  document.scrollingElement.scrollTop = 0;
  // Set persistence to local storage for session persistence
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      // Firebase Auth State Listener
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          setStatus(true);
          console.log(user.uid);

          const userDocRef = doc(firestore, 'users', user.uid);
          const q = query(userDocRef);

          const userDoc = await getDoc(q);
          if (userDoc.exists) {
            const userData = userDoc.data();
            setIsVendor(userData.isVendor === 1);
          }
        } else {
          setStatus(false);
          setIsVendor(false);
        }
      });

      // Cleanup subscription on component unmount
      return () => unsubscribe();
    })
    .catch((error) => {
      console.error("Error setting persistence: ", error);
    });
}, []);


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const handleLogout = () => {
    logout();
    setRedirectToHome(true); // Redirect to the homepage after logout
  };

  if (redirectToHome) {
    return <Navigate to="/" />; // Redirect to the homepage
  }

  return (
    <CartProvider> {/* Wrap the app in CartProvider to provide cart context */}
    
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {status ? (
          // Conditionally render navbar based on `isVendor`
          isVendor ? (
            <DefaultNavbar
              className="navbar"
              routes={vendorRoutes} // Add vendorRoutes when implemented
              action={{
                type: "internal",
                route: "/log-out",
                label: "Log Out",
                color: "accent",
              }}
              dark
            />
          ) : (
            <DefaultNavbar
              routes={routes} // Regular routes for non-vendor users
              action={{
                type: "internal",
                route: "/log-out",
                label: "Log Out",
                color: "accent",
              }}
              
              dark
            />
          )
        ) : (
          // Transparent navbar for guests (not logged in)
          <DefaultNavbar
            routes={routes}
            action={{
              type: "internal",
              route: "/sign-in",
              label: "Sign In",
              color: "accent",
            }}
            
            dark
          />
        )}

        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          {/* Main content area */}
          <div style={{ flex: 1 }}>
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Presentation />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/sign-in" element={<UserSignIn />} />
              <Route path="/welcometoeventflow" element={<RegisterUser />} />
              <Route path="/log-out" element={<LogOut />} />
               <Route path="/hi" element={<AboutUs />} /> 
              <Route path="/vendor/:id" element={<VendorPage />} />
              <Route path="/vendors/:city" element={<Vendor />} />
              <Route path="/events/:eventId" element={<EventDetails />} />
              <Route path="/vendor/:vendorId/inventory" element={<InventoryPage />} />
              <Route path="/vendor/vendorsettings" element={<VendorSettings />} />
              <Route path="/vendoroutreach" element={<AboutUs />} />
              <Route path="/threads/:threadId" element={<ThreadDetail />} />
              <Route path="/edititinerary/:eventId/:itemId" element={<EditItineraryItem />} />
              <Route path="/events/:eventId/check-in" element={<CheckInWrapper />} />
              <Route path="/vendor/dashboard" element={<AdminDashboard />} />
              <Route path="/events/:eventId/event-dashboard" element={<EventDashboardWrapper />} />
              <Route path="/vendor/:vendorId/add-inventory" element={<AddInventoryPage />} />
              <Route path="/cart/:eventId" element={<CartPage />} /> {/* Add CartPage route */}
              <Route path="/managebookings" element={<BookingManagement />} />
            </Routes>
          </div>
        </div>
        <div>
      {!cookieConsent && (
        <CookieConsent
          location="bottom"
          buttonText="Got it!"
          cookieName="userCookieConsent"
          style={{
            background: "#2B373B",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",
            padding: "10px",
          }}
          buttonStyle={{
            background: "#4e8c28",
            color: "#fff",
            borderRadius: "5px",
            padding: "10px 20px",
            fontSize: "14px",
          }}
          expires={365}
        >
          This website uses cookies to enhance the user experience. By continuing, you agree to our cookie policy.
        </CookieConsent>
      )}
    </div>
      </ThemeProvider>
    </CartProvider>
  );
}

const CheckInWrapper = () => {
  const { eventId } = useParams();
  return <CheckInPage eventId={eventId} />;
};

const EventDashboardWrapper = () => {
  const { eventId } = useParams();
  return <EventDashboard eventId={eventId} />;
};
