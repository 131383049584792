import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popper,
  Slider,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EFMajorCityLookup from '../../../../components/EFMajorCityLookup'; // Importing EFMajorCityLookup
import { city_names } from '../../../../ef-constants';
import { auth, firestore } from '../../../../firebase'; // Firebase setup
import UploadPhoto from '../../../../functions/UploadPhoto'; // Assuming this component is implemented elsewhere

// Custom Popper component to ensure dropdown isn't cut off
const CustomPopper = (props) => {
  return <Popper {...props} placement="bottom-start" />;
};

function CreateEvent() {
  const [step, setStep] = useState(1);
  const [eventDate, setEventDate] = useState(dayjs());
  const [newDate, setNewDate] = useState('');
  const [eventCapacity, setEventCapacity] = useState('');
  const [eventCity, setCity] = useState('');
  const [eventId, setEventId] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventBudget, setEventBudget] = useState(0);
  const [eventPhoto, setEventPhoto] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isTicketed, setIsTicketed] = useState(false);  // For ticketed event
  const [hasGuestbook, setHasGuestbook] = useState(false); // For guestbook
  const [allowPhotoUploads, setAllowPhotoUploads] = useState(false); // For photo uploads
  const [hasCheckIn, setHasCheckIn] = useState(false); // For check-in
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is small

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleSubmit = async () => {
    if (step === 5) {
      if (auth) {
        try {
          //const eventsCollection = collection(firestore, 'users', auth.currentUser.uid, 'Events');
          const eventsCollection = collection(firestore, "Events");

          const docRef = doc(eventsCollection);
          await setDoc(docRef, {
            event_name: eventName,
            event_capacity: eventCapacity,
            event_budget: eventBudget,
            event_date: newDate,
            event_created: new Date(),
            event_city: eventCity,
            event_id: docRef.id,
            ownerId: auth.currentUser?.uid,
            event_ticketed: isTicketed,  // Save ticketed event information
            event_guestbook: hasGuestbook,  // Save guestbook information
            event_photoUploads: allowPhotoUploads,  // Save photo upload info
            event_checkIn: hasCheckIn,  // Save check-in info
          });

          setSuccessMessage('Event added successfully!');
          setEventId(docRef.id);
          nextStep();
        } catch (error) {
          setErrorMessage('Error occurred while submitting the event.', error);
          console.log(error);
        }
      } else {
        alert('Please log in to submit the event.');
      }
    } else if (step === 6) {
      navigate(`/vendors/${eventCity}`);
    } else {
      nextStep();
    }
  };

  const handleDateChange = (date) => {
    setNewDate(date.format('YYYY-MM-DD'));
    setEventDate(date);
  };

  const handleCityChange = (value) => setCity(value);

  const handlePhotoUpload = async (photoUrl, eventId) => {
    try {
      const eventsRef = doc(firestore, 'Events', eventId);
     // const eventsRef = collection(firestore, "events");

      await updateDoc(eventsRef, {
        event_image: photoUrl,
      });

      setEventPhoto(photoUrl);
      setSuccessMessage('Event updated with photo!');
      navigate(`/vendors/${eventCity}`);
    } catch (error) {
      setErrorMessage('Error occurred while uploading the photo.');
    }
  };

  const handleCancel = () => {
    navigate(-1); // Optionally navigate the user back to the previous page or close the dialog
  };

    // Check if the user is logged in on component mount
    useEffect(() => {
      if (!auth.currentUser) {
        alert('You must be logged in to create an event!');
        navigate('/sign-in');  // Redirect to another page (e.g., dashboard)
      } 
    
    }, [navigate]);

  return (
    <>
      {/* Event Form Dialog */}
      <Dialog
        open={step === 1 || step === 2 || step === 3 || step === 4 || step === 5 || step === 6}
        onClose={handleCancel} // Close dialog when clicking outside or escape key
        maxWidth="lg"
        fullWidth
        sx={{
          '& .MuiDialogContent-root': {
            padding: isSmallScreen ? '16px' : '32px', // Adjust padding for small screens
            overflow: 'visible', // Ensures dropdown is not clipped
          },
        }}
      >
        <DialogTitle>Create Event</DialogTitle>
        <DialogContent>
          {/* Form Content */}
          {step === 1 && (
            <div>
              <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>What’s The Name Of Your Event?</p>
              <TextField
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </div>
          )}

          {step === 2 && (
            <div>
              <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Select Event Date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  value={eventDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disableToolbar
                />
              </LocalizationProvider>
            </div>
          )}

          {step === 3 && (
            <div>
              <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>How Many People Are You Expecting?</p>
              <TextField
                label="Event Capacity"
                value={eventCapacity}
                onChange={(e) => setEventCapacity(e.target.value)}
                type="number"
                fullWidth
                sx={{ mb: 2 }}
              />
            </div>
          )}

          {step === 4 && (
            <div>
              <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>What&apos;s Your Budget?</p>
              <Slider
                value={eventBudget}
                onChange={(e, value) => setEventBudget(value)}
                min={0}
                max={5000}
                step={50}
                marks
                valueLabelDisplay="auto"
              />
              <TextField
                label="Budget"
                value={eventBudget}
                onChange={(e) => setEventBudget(e.target.value)}
                type="number"
                fullWidth
                sx={{ mb: 2 }}
              />
            </div>
          )}

          {step === 5 && (
            <div>
              <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Which City Is This Event The Closest To?</p>
              <EFMajorCityLookup
                options={city_names} // Replace with actual city names
                label="Nearest Major City"
                onChange={handleCityChange} // Update city state
              />

              <div style={{ marginTop: '20px' }}>
                <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Is this event ticketed?</p>
                <TextField
                  value={isTicketed ? 'Yes' : 'No'}
                  onChange={(e) => setIsTicketed(e.target.value === 'Yes')}
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </TextField>

                <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Will there be a guestbook?</p>
                <TextField
                  value={hasGuestbook ? 'Yes' : 'No'}
                  onChange={(e) => setHasGuestbook(e.target.value === 'Yes')}
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </TextField>

                <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Will you allow photo uploads from guests?</p>
                <TextField
                  value={allowPhotoUploads ? 'Yes' : 'No'}
                  onChange={(e) => setAllowPhotoUploads(e.target.value === 'Yes')}
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </TextField>

                <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Will there be a check-in for this event?</p>
                <TextField
                  value={hasCheckIn ? 'Yes' : 'No'}
                  onChange={(e) => setHasCheckIn(e.target.value === 'Yes')}
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </TextField>
              </div>
            </div>
          )}

          {step === 6 && (
            <div>
              <p style={{ fontSize: isSmallScreen ? '1.5em' : '2em' }}>Upload Event Photo (Optional)</p>
              <UploadPhoto
                id={eventId}
                source="events"
                onUploadComplete={(photoUrl) => handlePhotoUpload(photoUrl, eventId)}
              />
            </div>
          )}
        </DialogContent>

        {/* Footer with Navigation Buttons */}
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>

          {step > 1 && (
            <Button onClick={prevStep} color="primary">
              Back
            </Button>
          )}

          <Button onClick={handleSubmit} color="primary">
            {step === 5 ? 'Finish' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success and Error Messages */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
    </>
  );
}

export default CreateEvent;
