import { Avatar, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Updated for React Router v6

const db = getFirestore();
const auth = getAuth();

function ThreadDetail() {
  const { threadId } = useParams();
  const [thread, setThread] = useState(null);
  const [responses, setResponses] = useState([]);
  const [newResponse, setNewResponse] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    // Fetch thread data
    const fetchThreadData = async () => {
      const threadDocRef = doc(db, 'threads', threadId);
      const threadDoc = await getDoc(threadDocRef);
      if (threadDoc.exists()) {
        setThread(threadDoc.data());
      } else {
        console.log('Thread not found');
      }
    };

    fetchThreadData();

    // Fetch responses for the thread
    fetchResponses();

  }, [threadId]);

  // Fetch responses for the current thread
  const fetchResponses = async () => {
    try {
      const responsesQuery = query(collection(db, 'threads', threadId, 'responses'), orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(responsesQuery);
      const fetchedResponses = [];

      for (let docSnapshot of querySnapshot.docs) {
        const response = docSnapshot.data();
        const userRef = doc(db, 'users', response.createdBy); // Fetch user details from 'users' collection
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Add the user details to the response
          fetchedResponses.push({
            ...response,
            createdByName: userData.username || 'Anonymous',
            createdByPhoto: userData.photoURL || 'https://www.example.com/default-avatar.png', // Default image URL
          });
        } else {
          // If user doesn't exist, use default values
          fetchedResponses.push({
            ...response,
            createdByName: 'Anonymous',
            createdByPhoto: 'https://www.example.com/default-avatar.png',
          });
        }
      }

      setResponses(fetchedResponses);
    } catch (error) {
      console.error('Error fetching responses:', error);
    }
  };

  const handleAddResponse = async () => {
    if (!newResponse) {
      alert('Please write a response before submitting');
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/sign-in');
        return;
      }

      const responseData = {
        text: newResponse,
        createdAt: new Date(),
        createdBy: user.uid,
      };

      // Add new response to Firestore
      await addDoc(collection(db, 'threads', threadId, 'responses'), responseData);

      // Clear the input field and fetch the updated list of responses
      setNewResponse('');
      setOpenDialog(false);
      fetchResponses(); // Re-fetch the responses after adding a new one
    } catch (error) {
      console.error('Error adding response: ', error);
    }
  };

  if (!thread) {
    return <Typography>Loading thread...</Typography>;
  }

  return (
    <Container>
      <Grid container mt={15} spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Typography variant="h4">{thread.title}</Typography>
            <Typography variant="body1">{thread.body}</Typography>
            <Typography variant="body2" color="textSecondary">
              Created by {thread.createdByName} on {new Date(thread.createdAt.seconds * 1000).toLocaleString()}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Button className='ef-sm-btn' onClick={() => setOpenDialog(true)}>
            Add a Response
          </Button>
        </Grid>

        <Grid item xs={12}>
          {responses.map(response => (
            <Card key={response.id} sx={{ padding: 2, marginBottom: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  {/* Display the user's profile image */}
                  <Avatar src={response.createdByPhoto} alt={response.createdByName} />
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">{response.text}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    - {response.createdByName}, {new Date(response.createdAt.seconds * 1000).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </Grid>

      {/* Add Response Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add a Response</DialogTitle>
        <DialogContent>
          <TextField
            label="Response"
            multiline
            rows={4}
            fullWidth
            value={newResponse}
            onChange={(e) => setNewResponse(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddResponse} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ThreadDetail;
