import { useState } from 'react';

// MUI Components

// Eventflow.app React components

// About Us page sections

// Routes

// Images


// VendorInquiry Component
import CommunityForum from './components/CommunityForum';

function Community() {
  const [venues, setEntries] = useState([]);
  const [city, setCity] = useState("");

  const handleCityChange = (event, value) => {
    // Set city only if a valid city is selected
    setCity(value);
  };

  return (
    <>
      <CommunityForum/>
    </>
  );
}

export default Community;
