import { Button, Card, CardContent, Grid, Snackbar, TextField } from "@mui/material";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import { useState } from "react";
import MKBox from "../../../../components/MKBox";
import { firestore } from "../../../../firebase";
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";
import EFQRCode from "./components/EFQRCode";

const CheckInPage = ({ eventId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    try {
      const eventDocRef = doc(firestore, "Events", eventId);
      await updateDoc(eventDocRef, {
        check_ins: arrayUnion({ name, email, checkedInAt: new Date() }),
      });
      setSuccessMessage("Thank you for checking in!");
      setName("");
      setEmail("");
    } catch (error) {
      console.error("Error updating check-in data:", error);
      setErrorMessage("Failed to check in. Please try again.");
    }
  };

  return (
    <BaseLayout
      title="Event Check-In"
      breadcrumb={[
        { label: "Events", route: "/events" },
        { label: "Check-In", route: `/events/${eventId}/check-in` },
      ]}
    >
      <MKBox mt={3}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                {/* QR Code Display */}
                <MKBox display="flex" justifyContent="center" my={3}>
                  <EFQRCode eventId={eventId} />
                </MKBox>

                {/* Check-In Form */}
                <form onSubmit={handleSubmit}>
                  <MKBox my={2}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </MKBox>
                  <MKBox my={2}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </MKBox>
                  <Button
                    type="submit"
                    className="ef-sm-btn"
                    fullWidth
                  >
                    Check In
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Snackbar for success and error messages */}
        <Snackbar
          open={!!successMessage}
          autoHideDuration={6000}
          onClose={() => setSuccessMessage("")}
          message={successMessage}
        />
        <Snackbar
          open={!!errorMessage}
          autoHideDuration={6000}
          onClose={() => setErrorMessage("")}
          message={errorMessage}
        />
      </MKBox>
    </BaseLayout>
  );
};

CheckInPage.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default CheckInPage;
