import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Cookies } from 'react-cookie-consent';
import bgImage from "../assets/images/bg1.jpg";
import { firestore } from '../firebase';

const EFMailingList = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    birthday: '',
    email: '',
    phone: '',
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setError('');
    try {
      const q = query(collection(firestore, 'mailingList'), where('email', '==', formData.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setError('This email is already in our system.');
        return;
      }

      await addDoc(collection(firestore, 'mailingList'), { ...formData, signup_date: new Date() });

      // Close the dialog and reset form
      onSubmit();
      setFormData({ name: '', birthday: '', email: '', phone: '' });
      Cookies.set('userSubscribed', 'true', { expires: 2 });

      onClose();
    } catch (e) {
      console.error('Error adding document:', e);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Grid container sx={{ width: '100%' }}>
        {/* Left side (Image with dark overlay) */}
        <Grid item xs={false} sm={6} sx={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${bgImage})`, // Replace with your image URL
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: 1,
            }}
          >
            {/* Dark overlay */}
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark overlay with opacity
                zIndex: 2,
              }}
            />
          </div>
        </Grid>

        {/* Right side (Form content) */}
        <Grid item xs={12} sm={6} sx={{ zIndex: 3, padding: 3 }}>
          <DialogTitle>Join Our Mailing List</DialogTitle>
          <DialogContent>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              margin="normal"
              label="Name"
              name="name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              label="Birthday"
              name="birthday"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={formData.birthday}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              label="Email"
              name="email"
              type="email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              label="Phone (Optional)"
              name="phone"
              fullWidth
              value={formData.phone}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              No Thanks
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Done
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

// PropTypes validation
EFMailingList.propTypes = {
  open: PropTypes.bool.isRequired, // open should be a boolean and is required
  onClose: PropTypes.func.isRequired, // onClose should be a function and is required
  onSubmit: PropTypes.func.isRequired, // onSubmit should be a function and is required
};

export default EFMailingList;
