import { Alert, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Icon, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useState } from "react";
import bgImage from "../../../../assets/images/bg1.jpg";
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import { businessTypeDropdown } from "../../../../ef-constants";
import { firestore } from "../../../../firebase";

// Firestore setup
const db = getFirestore();

function VendorInquiryOld() {
  const [open, setOpen] = useState(false);
  const [city, setCity] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    businessType: [],
    address: "",
    description: "",
    website: "",
  });
  const [vendorType, setVendorType] = useState([]);
  const [featureVendor, setFeatureVendor] = useState(false);
  const [error, setError] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };

  const handleTypeChange = (event) => {
    setVendorType(event.target.value);
    setFormData({
      ...formData,
      businessType: event.target.value,
    });
  };

  const handleFeatureVendorChange = (event) => {
    setFeatureVendor(event.target.checked);
  };

  const validateEmail = (email) => {
    // Simple email regex for validation
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    setError('');
    if (!formData.name || !formData.email || !formData.phone || !formData.businessType.length || !formData.address || !formData.description || !formData.website) {
      setError("All fields are required.");
      return;
    }
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email.");
      return;
    }

    try {
      const vendorCollection = collection(firestore, "vendorInquiries");

      // Vendor registration logic, create the vendor document
      const vendorData = {
        vendor_name: formData.name,
        vendor_email: formData.email,
        vendor_phone: formData.phone,
        vendor_type: formData.businessType,
        vendor_address: formData.address,
        vendor_description: formData.description,
        vendor_website: formData.website,
        vendor_featured: featureVendor,
        vendor_created: Date.now(),
      };

      // Add vendor data to Firestore
      await addDoc(vendorCollection, vendorData);

      setSuccessMessage("Vendor successfully added! You will hear from us soon :)");

      // Close the dialog and reset form
      setOpen(false);
      setFormData({
        name: "",
        email: "",
        phone: "",
        businessType: [],
        address: "",
        description: "",
        website: "",
      });
      setFeatureVendor(false);
    } catch (e) {
      console.error("Error adding document:", e);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div>
      <MKBox
        display="flex"
        alignItems="center"
        borderRadius="xl"
        my={2}
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 }, height: "57vh" }}>
            <h2
              style={{
                color: "white",
                marginBottom: "1rem",
                textAlign: "left",
                fontSize: { xs: "0.875rem", sm: ".9rem", md: "1.5rem" },
              }}
            >
              Join Our Vendor Network
            </h2>

            <MKTypography
              sx={{
                fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem" },
                color: "#fff",
                marginBottom: ".5rem",
              }}
              variant="body1"
            >
              Ready to showcase your business? Join EventFlow as a vendor to expand your reach and connect with customers
              looking for services like yours. Fill out the form to get started.
            </MKTypography>

            <MKTypography
              component="a"
              onClick={() => setOpen(true)}
              variant="h3"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: { xs: "1rem", sm: "2rem" },
                cursor: "pointer",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Become a Vendor <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      {/* Snackbar for error and success messages */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={8000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />

      {/* Vendor Registration Form Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Register Your Business</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}

          <TextField
            margin="normal"
            label="Business Name"
            name="name"
            fullWidth
            value={formData.name || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={formData.email || ""}
            onChange={handleEmailChange}
            helperText={error && "Please enter a valid email address"}
            error={error && !validateEmail(formData.email)}
          />
          <TextField
            margin="normal"
            label="Phone"
            name="phone"
            fullWidth
            value={formData.phone || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            label="Address"
            name="address"
            fullWidth
            value={formData.address || ""}
            onChange={handleChange}
          />

          {/* Business Type (Multiple Selection) */}
          <MKBox mb={2}>
            <InputLabel>Business Type</InputLabel>
            <Select
              labelId="vendorType"
              id="vendorType"
              value={vendorType || []}
              onChange={handleTypeChange}
              fullWidth
              multiple
            >
              {businessTypeDropdown.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </MKBox>

          <TextField
            margin="normal"
            label="Description"
            name="description"
            multiline
            rows={4}
            fullWidth
            value={formData.description || ""}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            label="Website"
            name="website"
            fullWidth
            value={formData.website || ""}
            onChange={handleChange}
          />

          {/* Feature Vendor Checkbox */}
          <FormControlLabel
            control={<Checkbox checked={featureVendor} onChange={handleFeatureVendorChange} />}
            label="Would you like to be featured as a vendor?"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default VendorInquiryOld;
