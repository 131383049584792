/*
* Usage: <CheckInButton eventId="12345" variant="contained" size="large" fullWidth />

*/
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const EFDashboardButton = ({ eventId, variant = "contained", size = "medium", fullWidth = false }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/events/${eventId}/event-dashboard`);
 
  };

  return (
    <Button
      variant={variant}
      size={size}
      className="ef-sm-btn"
      fullWidth={fullWidth}
      onClick={handleNavigation}
    >
      Dashboard
    </Button>
  );
};

EFDashboardButton.propTypes = {
  eventId: PropTypes.string.isRequired, // Event ID for navigation
  variant: PropTypes.oneOf(["contained", "outlined", "text"]), // Material-UI button variant
  size: PropTypes.oneOf(["small", "medium", "large"]), // Button size
  fullWidth: PropTypes.bool, // Whether the button takes full width
};

export default EFDashboardButton;
