import React from 'react';

interface VideoPlayerProps {
  videoUrl: string;
}

const EFVideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl }) => {
  const isYouTubeVideo = (url: string): boolean => {
    return (
      url.includes("youtube.com/watch?v=") ||
      url.includes("youtu.be/") ||
      url.includes("youtube.com/shorts/")
    );
  };

  const getYouTubeEmbedUrl = (url: string): string => {
    let videoId: string | undefined;

    if (url.includes("youtube.com/shorts/") || url.includes("youtu.be/")) {
      // Handle YouTube Shorts format
      videoId = url.split("/shorts/")[1] || url.split("/")[3]; // Extract video ID
    } else if (url.includes("youtube.com/watch?v=")) {
      // Handle regular YouTube video format
      videoId = url.split("v=")[1]?.split("&")[0]; // Extract video ID
    }

    // Return the embed URL with controls=0, modestbranding=1, rel=0 (no related videos)
    return `https://www.youtube.com/embed/${videoId}?controls=0&modestbranding=1&rel=0`;
  };

  return (
    <>
      {isYouTubeVideo(videoUrl) ? (
        <iframe
          width="100%"
          height="350"
          src={getYouTubeEmbedUrl(videoUrl)}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Eventflow Vendor Video"
        />
      ) : (
        <video width="100%" height={450} controls>
          <source src={videoUrl} type="video/mp4" />
          <source src="mov_bbb.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
};

export default EFVideoPlayer;
