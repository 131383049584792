import { Box, Grid } from '@mui/material';
import React from 'react';
import efLogo from "../assets/images/ef_logo_25.png";
import TransparentBlogCard from '../examples/Cards/BlogCards/TransparentBlogCard';

// Vendor interface to type the posts correctly
interface Vendor {
  vendor_id: string;
  vendor_pictureUrl: string;
  vendor_name: string;
  vendor_type: string;
  vendor_majorCity: string; // Added vendor_majorCity to the interface
}

interface EFCardProps {
  posts: Vendor[];
  layout?: 'carousel' | 'grid'; // layout prop to define the layout (carousel or grid)
  displayField?: 'city' | 'type'; // New prop to determine which field to display
}

const EFCard: React.FC<EFCardProps> = ({ posts, layout = 'grid', displayField = 'type' }) => {
  const renderDescription = (vendor: Vendor): string => {
    if (displayField === 'city') {
      return vendor.vendor_majorCity; // Return city as a string
    }
    return vendor.vendor_type; // Default: Return type as a string
  };

  if (layout === 'carousel') {
    // Carousel Layout: All items in a single row (side-by-side)
    return (
      <Box sx={{ display: 'flex', overflow: 'hidden', flexWrap: 'nowrap' }}>
        {posts.map((vendor, i) => (
          <Box key={vendor.vendor_id || i} sx={{ marginRight: '20px' }}>
            <TransparentBlogCard
              image={vendor?.vendor_pictureUrl || efLogo}
              title={vendor?.vendor_name}
              description={renderDescription(vendor)} // Render description as string
              action={{
                type: "internal",
                route: `/vendor/${vendor.vendor_id}`,
                color: "info",
                label: "",
              }}
            />
          </Box>
        ))}
      </Box>
    );
  } else {
    // Grid Layout: Standard multi-column layout
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          {posts.map((vendor, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={vendor.vendor_id || i}>
              <TransparentBlogCard
                image={vendor?.vendor_pictureUrl || efLogo}
                title={vendor?.vendor_name}
                description={renderDescription(vendor)} // Render description as string
                action={{
                  type: "internal",
                  route: `/vendor/${vendor.vendor_id}`,
                  color: "info",
                  label: "",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
};

export default EFCard;
