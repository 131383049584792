/*
=========================================================
* Eventflow.app React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Eventflow.app React components
import MKBox from "../../../../components/MKBox";

// Eventflow.app React examples
import EFVideoPlayer from "../../../../components/EFVideoPlayer";
import WhatWereLookingFor from "./LookingFor";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <WhatWereLookingFor/>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
           <EFVideoPlayer videoUrl="https://youtube.com/shorts/pkjRR-v3xFU?si=d_HZiQSVMEHnzViR"/>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
