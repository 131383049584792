import { Delete } from '@mui/icons-material';
import { Box, Button, CardContent, CardMedia, Grid, TextField, Typography } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // For accessing URL params
import EFBanner from '../../../components/EFBanner';
import { firestore } from '../../../firebase';
import { useCart } from './CartContext';

const CartPage = () => {
  const { eventsCart = {}, getTotalPrice, removeFromCart, updateQuantity, loading } = useCart();
  const [localCart, setLocalCart] = useState([]);
  const [budget, setBudget] = useState(null); // State to hold the event budget
  const [isOverBudget, setIsOverBudget] = useState(false); // State to check if over budget
  const { eventId } = useParams(); // Get eventId from URL params

  // Synchronize localCart with the context cart
  useEffect(() => {
    const cartItems = Object.values(eventsCart).flat();
    setLocalCart(cartItems);
  }, [eventsCart]);

  // Fetch event data (budget) on page load
  useEffect(() => {
    const fetchEventBudget = async () => {
      if (eventId) {
        try {
          const eventDocRef = doc(firestore, 'Events', eventId);
          const eventSnapshot = await getDoc(eventDocRef);
          if (eventSnapshot.exists()) {
            const eventData = eventSnapshot.data();
            setBudget(eventData?.event_budget || null); // Assuming the budget field exists
          } else {
            console.error('Event not found');
          }
        } catch (error) {
          console.error('Error fetching event budget: ', error);
        }
      }
    };

    fetchEventBudget();
  }, [eventId]);

  // Check if cart total exceeds event budget
  useEffect(() => {
    if (budget !== null) {
      const totalPrice = getTotalPrice();
      setIsOverBudget(totalPrice > budget);
    }
  }, [budget, getTotalPrice]);

  // Handle the quantity update in both local state and Firestore
  const handleQuantityChange = async (itemId, newQuantity) => {
    if (newQuantity <= 0 || isNaN(newQuantity)) {
      return; // Prevent setting quantity to zero or negative
    }

    setLocalCart(localCart.map(item =>
      item.id === itemId ? { ...item, quantity: newQuantity } : item
    ));

    await updateQuantity(itemId, newQuantity);
  };

  if (loading) {
    return <Typography>Loading your cart...</Typography>;
  }

  return (
    <div>
      <Grid container justifyContent="center" alignItems="center" sx={{ paddingY: 2 }}>
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }} mt={8}>
          <EFBanner text="Welcome to Our Website! Check out our latest offers!" speed={20} />
        </Grid>
      </Grid>

      <Box
        sx={{
          backgroundColor: '#F2F2F2',
          borderRadius: 2,
          boxShadow: 3,
          p: 3,
          mx: 'auto',
          width: { xs: '95%', sm: '80%', md: '70%' },
          mt: 0,
        }}
      >
        {/* Budget Alert Box */}
        {isOverBudget && (
          <Box
            sx={{
              backgroundColor: '#f44336', // Red color for over budget
              color: 'white',
              padding: 2,
              borderRadius: 2,
              marginBottom: 2,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            You are over your event&apos;s budget!
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom>Your Cart</Typography>

            {localCart.length === 0 ? (
              <Typography>No items in the cart.</Typography>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {localCart.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '15px',
                      backgroundColor: '#fff',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      justifyContent: 'space-between',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    {item.imageUrl && (
                      <CardMedia
                        component="img"
                        alt={item.name}
                        image={item.imageUrl}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                    )}

                    <CardContent style={{ flex: 1, marginLeft: '20px' }}>
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography variant="body1">Price: ${item.price}</Typography>
                      
                      <TextField
                        label="Quantity"
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                        sx={{ width: '80px' }}
                      />
                    </CardContent>

                    <Button
                      color="error"
                      onClick={() => removeFromCart(item.id)}
                      sx={{ marginLeft: '10px' }}
                    >
                      <Delete />
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: 2,
                borderRadius: 2,
                boxShadow: 2,
                position: 'sticky',
                top: 0,
              }}
            >
              <Typography variant="h5" gutterBottom>Total</Typography>
              <Typography variant="h6">
                ${isNaN(getTotalPrice()) ? 0 : getTotalPrice().toFixed(2)}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CartPage;
