

// @mui material components
import Autocomplete from '@mui/material/Autocomplete';
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import MKBox from "../../components/MKBox";
import MKSocialButton from "../../components/MKSocialButton";


// Eventflow.app React examples
import headerImage from "../../assets/images/2025eventflowbg.png";
import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Presentation page sections
//import bgImage from "../../assets/images/bgwater.png";

// Presentation page components
import { city_data } from '../../ef-constants';
import BuiltByDevelopers from "../Presentation/components/BuiltByDevelopers";
// Routes
import EFCreateEvent from '../../components/EFCreateEvent';
import EFMailingList from '../../components/EFMailingList';
import footerRoutes from "../../footer.routes";
import EFVendorInquiryButton from '../Vendors/Inquiry';
import FeaturedVendors from './components/FeaturedVendors';
import FeaturedBlurbs from './sections/FeaturedBlurbs';

// Images



function Presentation() {

  const [ city, setCity ] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Automatically open the dialog after 3 seconds
    const timer = setTimeout(() => {
      setOpen(true);
    }, 3000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Do something after the user submits the form (optional)
    Cookies.set('userSubscribed', 'true', { expires: 7 });
  };

  const handleCityChange = (event, value) => {
    setCity(value);
    //console.log(value); // Log the new value directly
  };

  return (
    <>
      
      <MKBox
        minHeight="75vh"
        width="100%"
        mt={10+'vh'}
        sx={{
          backgroundImage: `url(${headerImage})`,
          backgroundColor:'#ff8a41', 
          backgroundSize: "contain",
          backgroundPosition: "center",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.5)", // Shadow for depth around the image
          backgroundRepeat: "no-repeat",  // Prevent the image from repeating
          display: "grid",
          placeItems: "center",
      
          minHeight: {
            xs: '30vh',  // For small screens (xs), set minHeight to 50vh
            sm: '75vh',  // For larger screens (sm and above), keep minHeight as 75vh
          }
        }}
      >
        <Container>
          <Grid mt={30} container item xs={12} lg={7} justifyContent="center" mx="auto">

            <p
    style={{
      textAlign: "center",
      padding: ".5em",
      marginY: "2px",
    //  backgroundColor: "#16324F",  // Fallback background color
      opacity: ".85",
      backgroundImage: "linear-gradient(circle, #16324F, #4B6F8C)", // Gradient background
      color: "#fff",  // White text color for contrast
      borderRadius: "8px",  // Rounded corners
     // boxShadow: "0 0 10px rgba(22, 50, 79, 0.7), 0 0 20px rgba(75, 111, 140, 0)", // Fading shadow effect
      
    }}
  >
  Your ultimate platform for orchestrating unforgettable events. </p>

            <div>
            <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={city_data.map((city) => city.city_name)}
  sx={{ width: "70vw", backgroundColor: "#FFF", marginY: "8px" }}
  onChange={handleCityChange} // Update here
  renderInput={(params) => <TextField {...params} label="Nearest Major City" />}
/>
<Link  
  className="btn btn-pink ef-btn"
  role="button"
  to={`/vendors/${city}`} 
  style={{ 
    borderRadius: "1rem", // Rounded corners for the button
    textAlign: "center",
    padding: "12px 20px", // Padding adjusted for a larger button
    margin: "10px auto",
    width: "50%",
    height: "60px", // Fixed height for the button
    display: "block",
    color: "#fff",
    backgroundColor: "#1e6c97",
    fontSize: "1.1em", // Larger text for emphasis
    fontWeight: "bold",
    textDecoration: "none", // Remove underline
    transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Light shadow for depth
  }}
  onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"} // Make button bigger on hover
  onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset button size when not hovered
> 
  GO
</Link>


    </div>
          </Grid>
          

 
        </Container>
      </MKBox>

      <EFMailingList open={open} onClose={handleClose} onSubmit={handleSubmit} />


      <Card
        sx={{
          p: 2,
          mx: { xs: 4, lg: 6 },
          mt: {xs: 2, lg: -8},
          mb: 4,
          backgroundImage: ({ functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba("#fff", 0.85),  // Start color with opacity
            rgba("#1e6c97", 1)   // End color with opacity
          )}`, 
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        <FeaturedBlurbs/>
                {/* <Blurbs/> */}
<FeaturedVendors/>
<EFCreateEvent/>

                {/* <Information /> */}

        
       {/* 
       <Counters />
       <DesignBlocks />

        <Pages />
        */
      }
       
       <Container>
          <BuiltByDevelopers />
        </Container>
{/* 
        <Container >
          {// TODO: this 
          }
          <Grid mt={2} container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Discover and Connect"
                description="Tap into our curated network of vendors for weddings and milestone 
                birthdays. Choose your ideal venue, caterer, and more with ease."
                action={{
                  type: "external",
                  route: "https://www.eventflow.app/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plan and Organize"
                description="Use our innovative tools to schedule, budget, and oversee your event, 
                ensuring a delightful planning experience from anywhere, anytime."
                action={{
                  type: "internal",
                  route: "/createnewevent",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Celebrate and Share"
                description="Experience the magic of your dream event and share the joy with everyone who matters."
                action={{
                  type: "internal",
                  route: "/myevents",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}
        {// <Testimonials /> <Download />
}

        
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <h3 style={{
                  marginBottom: "5px",
                  color: "#fff"
                }}>
                  Make every moment eventful<br></br> with Eventflow!
                </h3>
                <EFVendorInquiryButton/>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20out%20Eventflow%20app%20made%20by%20%40KrystalMauriLLC%20%%20%%20%&amp;url=https%3A%2F%2Fwww.eventflow.app"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.eventflow.app"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.eventflow.app"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
