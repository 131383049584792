import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import { addDoc, collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useState } from "react";
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Firestore setup
const db = getFirestore();

function BuiltByDevelopers() {
  const bgImage =
    "https://gomag.com/wp-content/uploads/2019/03/iStock-1012989218.jpg";

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    birthday: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState("");

  const calculateMilestones = (birthday) => {
    const milestones = [18, 21, 30, 40, 50, 60, 65, 70, 80, 90, 100];
    const birthdayDate = new Date(birthday);
    return milestones.map((age) => {
      const milestoneDate = new Date(birthdayDate);
      milestoneDate.setFullYear(milestoneDate.getFullYear() + age);
      return {
        age,
        date: milestoneDate.toISOString().split("T")[0],
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setError("");
    try {
      const q = query(collection(db, "mailingList"), where("email", "==", formData.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setError("This email is already in our system.");
        return;
      }

      const milestoneDates = calculateMilestones(formData.birthday);
      const dataToSave = { ...formData, milestoneDates };

      await addDoc(collection(db, "mailingList"), dataToSave);
   //   console.log("User successfully signed up!");

      setOpen(false);
      setFormData({
        name: "",
        birthday: "",
        email: "",
        phone: "",
        signup_date: new Date(),

      });
    } catch (e) {
      console.error("Error adding document:", e);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div>
      <MKBox
        display="flex"
        alignItems="center"
        borderRadius="xl"
        my={2}
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 }, height: "57vh" }}>
            <h4
              style={{
                color: "white",
                marginBottom: "1rem",
                textAlign: "left",
                fontSize: { xs: "0.675rem", sm: ".9rem", md: "1.5rem" },
              }}
            >
              Effortless Party Planning with EventFlow
            </h4>

            <MKTypography
              sx={{
                fontSize: { xs: "0.875rem", sm: "1rem", md: "1.25rem" },
                color: "#fff",
                marginBottom: ".5rem",
              }}
              variant="body1"
            >
              Planning a birthday party should be fun and exciting, not stressful. EventFlow&apos;s birthday planning
              checklists simplify the process, helping you manage every detail effortlessly. From budgeting and guest lists
              to finding the best caterers and entertainers, our checklists ensure nothing is overlooked. Focus on enjoying
              the celebration while we take care of the organization.
            </MKTypography>

            <MKTypography
              component="a"
              onClick={() => setOpen(true)}
              variant="body2"
              color="white"
              fontWeight="regular"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: { xs: "0.875rem", sm: "1rem" },
                cursor: "pointer",
                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translateX(3px)`,
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },
                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: `translateX(6px)`,
                },
              }}
            >
              Join our mailing list <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Join Our Mailing List</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            margin="normal"
            label="Name"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            label="Birthday"
            name="birthday"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.birthday}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            label="Email"
            name="email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            label="Phone (Optional)"
            name="phone"
            fullWidth
            value={formData.phone}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BuiltByDevelopers;
