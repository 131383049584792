import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, SnackbarContent, TextField, Typography } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; // Import dayjs for handling dates
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import PropTypes from 'prop-types'; // Import PropTypes
import { useEffect, useState } from 'react';
import { firestore } from '../../../../../firebase'; // Firebase configuration

const ManageAvailability = ({ vendorId }) => {
  const [availableTimes, setAvailableTimes] = useState([]); // List of available times from Firestore
  const [loading, setLoading] = useState(true); // Loading state
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const [successMessage, setSuccessMessage] = useState(''); // Success message state
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Set default selected date to today
  const [selectedTimes, setSelectedTimes] = useState([]); // Array of selected times for the selected date
  const [openDialog, setOpenDialog] = useState(false); // Dialog for adding new availability
  const [copyDialogOpen, setCopyDialogOpen] = useState(false); // Dialog for copying availability
  const [datesToCopy, setDatesToCopy] = useState([]); // Dates selected for copying availability

  // Handle Snackbar close event
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Fetch available times for a vendor from Firestore
  const fetchAvailableTimes = async () => {
    try {
      const timesRef = collection(firestore, 'vendors', vendorId, 'AvailableTimes');
      const querySnapshot = await getDocs(timesRef);

      const availableList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAvailableTimes(Array.isArray(availableList) ? availableList : []);
      console.log('available times in management , ', availableList);
    } catch (error) {
      console.error('Error fetching available times:', error);
      setErrorMessage('Error fetching available times.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle adding multiple available time slots
  const handleAddAvailability = async () => {
    if (!selectedDate || selectedTimes.length === 0) {
      setErrorMessage('Please select both a date and at least one time.');
      setOpenSnackbar(true);
      return;
    }

    try {
      for (const time of selectedTimes) {
        const newAvailabilityRef = doc(firestore, 'vendors', vendorId, 'AvailableTimes', `${selectedDate?.toISOString()}-${time}`);
        await setDoc(newAvailabilityRef, {
          date: selectedDate?.toISOString(),
          time: time,
        });
      }

      setSuccessMessage(`Successfully added availability for ${selectedDate?.format('MMMM D, YYYY')} at times: ${selectedTimes.join(', ')}.`);
      setOpenSnackbar(true);

      setSelectedDate(dayjs()); // Reset to current date after adding
      setSelectedTimes([]);
      setOpenDialog(false);

      fetchAvailableTimes();
    } catch (error) {
      console.error('Error adding availability:', error);
      setErrorMessage('Error adding availability.');
      setOpenSnackbar(true);
    }
  };

  // Handle copying availability to selected days
  const handleCopyAvailability = async () => {
    if (!datesToCopy.length) {
      setErrorMessage('Please select at least one day to copy availability to.');
      setOpenSnackbar(true);
      return;
    }

    try {
      // Ensure all dates in `datesToCopy` are valid dayjs objects
      for (const date of datesToCopy) {
        if (!dayjs(date).isValid()) {
          console.error('Invalid date:', date);
          setErrorMessage('One or more selected dates are invalid.');
          setOpenSnackbar(true);
          return;
        }

        for (const time of selectedTimes) {
          const newAvailabilityRef = doc(firestore, 'vendors', vendorId, 'AvailableTimes', `${date.toISOString()}-${time}`);
          await setDoc(newAvailabilityRef, {
            date: date.toISOString(),
            time: time,
          });
        }
      }

      setSuccessMessage(`Successfully copied availability to ${datesToCopy.length} day(s).`);
      setOpenSnackbar(true);

      setCopyDialogOpen(false);
      setDatesToCopy([]); // Reset dates after copying
      fetchAvailableTimes();
    } catch (error) {
      console.error('Error copying availability:', error);
      setErrorMessage('Error copying availability.');
      setOpenSnackbar(true);
    }
  };

  // Handle dialog close event
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDate(dayjs()); // Reset selected date to today on dialog close
    setSelectedTimes([]);
  };

  // Handle copy dialog close event
  const handleCloseCopyDialog = () => {
    setCopyDialogOpen(false);
    setDatesToCopy([]); // Clear selected dates
  };

  // Fetch available times when component is mounted
  useEffect(() => {
    fetchAvailableTimes();
  }, [vendorId]);

  // Generate 24-hour time slots
  const generateTimes = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? `0${i}` : i;
      times.push(`${hour}:00`);
    }
    return times;
  };

  // Check if a time is already available in the Firestore list
  const isTimeAvailable = (time) => {
    return availableTimes.some((available) => available.time === time && dayjs(available.date).isSame(selectedDate, 'day'));
  };

  return (
    <Box sx={{ padding: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h3" gutterBottom>
        Manage Your Available Dates and Times
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Date Picker for selecting a date */}
          <div>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                value={selectedDate}
                onChange={(newDate) => {
                  setSelectedDate(newDate); // Handle date change
                  setSelectedTimes([]); // Reset selected times when a new date is picked
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disableToolbar
                shouldDisableDate={(date) => {
                  if (!Array.isArray(availableTimes)) return true;
                  return !availableTimes.some((time) => dayjs(time.date).isSame(date, 'day'));
                }}
              />
              <h4 style={{ textAlign: 'center'}}>Preview</h4>
            </LocalizationProvider>
            
          </div>

          {/* Time selection buttons */}
          <Grid container spacing={2} sx={{ marginTop: 3 }}>
            {generateTimes().map((time) => (
              <Grid item key={time}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setSelectedTimes((prevTimes) => {
                      if (prevTimes.includes(time)) {
                        return prevTimes.filter((t) => t !== time); // Remove if already selected
                      } else {
                        return [...prevTimes, time]; // Add if not selected
                      }
                    });
                  }}
                  sx={{
                    marginBottom: 1,
                    borderColor: isTimeAvailable(time) || selectedTimes.includes(time) ? 'success.main' : 'grey.400', // Green if available or selected
                    color: isTimeAvailable(time) || selectedTimes.includes(time) ? 'success.main' : 'inherit', // Green if available or selected
                    backgroundColor: selectedTimes.includes(time) ? 'success.main' : 'inherit', // Green if selected
                  }}
                >
                  {time}
                </Button>
              </Grid>
            ))}
          </Grid>

          {/* Button to open dialog for adding new availability */}
          <Grid item sx={{ marginTop: 3 }}>
            <Button
              className='ef-sm-btn'
              onClick={() => setOpenDialog(true)}
              fullWidth
            >
              Add New Availability
            </Button>
          </Grid>

          {/* Button to open Copy Availability Dialog */}
          <Grid item sx={{ marginTop: 3 }}>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                backgroundColor: "#000",
              }}
              onClick={() => setCopyDialogOpen(true)}
              fullWidth
            >
              Copy Availability to Other Dates
            </Button>
          </Grid>
        </>
      )}

      {/* Dialog for adding new availability */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add New Availability</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={selectedDate}
              onChange={(newDate) => {
                setSelectedDate(newDate);
                setSelectedTimes([]); // Reset times when changing the date
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disableToolbar
            />
          </LocalizationProvider>

          {/* Time buttons to choose availability */}
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {generateTimes().map((time) => (
              <Grid item key={time}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setSelectedTimes((prevTimes) => {
                      if (prevTimes.includes(time)) {
                        return prevTimes.filter((t) => t !== time); // Remove if already selected
                      } else {
                        return [...prevTimes, time]; // Add if not selected
                      }
                    });
                  }}
                  sx={{
                    marginBottom: 1,
                    borderColor: selectedTimes.includes(time) ? 'info.main' : 'grey.400',
                    color: selectedTimes.includes(time) ? 'info.main' : 'inherit',
                  }}
                >
                  {time}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddAvailability} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for copying availability */}
      <Dialog open={copyDialogOpen} onClose={handleCloseCopyDialog}>
        <DialogTitle>Copy Availability</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Select the dates to which you want to copy the availability:
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={datesToCopy}
              onChange={(newDates) => {
                // Ensure all selected dates are valid
                const validDates = newDates.filter(date => dayjs(date).isValid());
                setDatesToCopy(validDates); // Update with valid dates
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              disableToolbar
              allowKeyboardControl
              multiple
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCopyDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCopyAvailability} color="primary">
            Copy
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for showing status messages */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <SnackbarContent
          message={errorMessage || successMessage}
          sx={{
            backgroundColor: errorMessage ? 'red' : 'green',
            color: 'white',
            fontWeight: 'bold',
            padding: '8px 16px',
          }}
        />
      </Snackbar>
    </Box>
  );
};

// Add PropTypes validation for vendorId
ManageAvailability.propTypes = {
  vendorId: PropTypes.string.isRequired, // Ensure vendorId is a string and required
};

export default ManageAvailability;
