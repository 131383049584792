import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

// EventDetailsInfo Component to display event data dynamically
const EventDetailsInfo = ({ city, capacity, budget, date }) => {
  const [event, setEvent] = useState(null);  // State to store event data
  const [loading, setLoading] = useState(true);  // Loading state
  console.log('passed date ', budget)

  if ((budget == "0") || (!budget)){
    budget="Free";
  }
  else {
    budget = `$${budget}`;
  }
  
  // Format date in UTC (if it is a date string in ISO format)
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    // Adjusting to UTC format to avoid timezone shifting
    return dateObj.toISOString().split('T')[0];  // Get only the date part (yyyy-mm-dd)
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        {/* Date Section */}
        <Grid item xs={6} sm={6} md={3}>
          <Card sx={{ textAlign: 'center', background: 'linear-gradient(to right, #CC6E32, #FF8A41)', paddingY: '1em' }}>
            <CardMedia
              component="img"
              alt="Date"
              image="https://img.icons8.com/ios/452/calendar.png"  // Calendar icon image
              sx={{
                objectFit: 'contain',
                height: '100%',
                width: 'auto',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                Date
              </Typography>
              <Typography variant="body2" color="#fff">
                {formatDate(date)}  {/* Format date */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* City Section */}
        <Grid item xs={6} sm={6} md={3}>
          <Card sx={{ textAlign: 'center', background: 'linear-gradient(to right, #CC6E32, #FF8A41)', paddingY: '1em' }}>
            <CardMedia
              component="img"
              alt="City"
              image="https://img.icons8.com/ios/452/marker.png"  // City icon image
              sx={{
                objectFit: 'contain',
                height: '100%',
                width: 'auto',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                City
              </Typography>
              <Typography variant="body2" color="#fff">
                {city}  {/* Display city name */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Capacity Section */}
        <Grid item xs={6} sm={6} md={3}>
          <Card sx={{ textAlign: 'center', background: 'linear-gradient(to right, #CC6E32, #FF8A41)', paddingY: '1em' }}>
            <CardMedia
              component="img"
              alt="Capacity"
              image="https://media.istockphoto.com/id/1533834784/vector/symbol-people-icon-vector-graphics.jpg?s=612x612&w=0&k=20&c=Tq8HHi5fDpsCtkjyDFNAQ0K2sPjhA_ZulcltxgJG-ZM="  // Group icon image
              sx={{
                objectFit: 'contain',
                height: '100%',
                width: 'auto',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                Capacity
              </Typography>
              <Typography variant="body2" color="#fff">
                {capacity}  {/* Display event capacity */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Budget Section */}
        <Grid item xs={6} sm={6} md={3}>
          <Card sx={{ textAlign: 'center', background: 'linear-gradient(to right, #145883, #1e6c97)', paddingY: '1em' }}>
            <CardMedia
              component="img"
              alt="Budget"
              image="https://img.icons8.com/ios/452/money-bag.png"  // Money bag icon image
              sx={{
                objectFit: 'contain',
                height: '100%',
                width: 'auto',
                maxHeight: '100px',
                margin: 'auto',
              }}
            />
            <CardContent>
              <Typography variant="h6" color="#fff">
                Budget
              </Typography>
              <Typography variant="body2" color="#fff">
                {budget}  {/* Display event budget */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

// PropTypes validation for the component props
EventDetailsInfo.propTypes = {
  city: PropTypes.string.isRequired,      // city should be a string and is required
  capacity: PropTypes.string.isRequired,  // capacity should be a number and is required
  budget: PropTypes.number.isRequired,    // budget should be a number and is required
  date: PropTypes.string.isRequired,      // date should be a string and is required
};

export default EventDetailsInfo;
