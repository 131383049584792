import { Box, CircularProgress, Snackbar } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import { useEffect, useState } from 'react';
import Carousel from 'react-slick';
import 'slick-carousel/slick/slick-theme.css'; // Import slick carousel theme CSS
import 'slick-carousel/slick/slick.css'; // Import slick carousel CSS
import EFCard from '../../components/EFCard'; // Assuming EFCard is a functional component that displays vendor info
import { firestore } from '../../firebase';

const SimilarVendorsCarousel = ({ vendorType, city }) => {
  const [similarVendors, setSimilarVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch similar vendors based on vendor type and city
  const getSimilarVendors = async () => {
    try {
      const q = query(
        collection(firestore, 'vendors'),
        where('vendor_type', '==', vendorType),
        where('vendor_majorCity', '==', decodeURIComponent(city)) // Decode the city if it's URL-encoded
      );
      const querySnapshot = await getDocs(q);

      // Map the fetched data and remove duplicates based on vendor_id
      const entries = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          vendor_id: doc.id,
          vendor_pictureUrl: data.vendor_pictureUrl || '',
          vendor_name: data.vendor_name || 'Unknown Vendor',
          vendor_type: data.vendor_type || 'Unknown Type',
          vendor_majorCity: data.vendor_majorCity || 'Unknown City',
        };
      });

      // Remove duplicates based on vendor_id
      const uniqueVendors = Array.from(
        new Map(entries.map((vendor) => [vendor.vendor_id, vendor])).values()
      );

      setSimilarVendors(uniqueVendors); // Set the unique vendors to state
      setLoading(false); // Stop the loading indicator
    } catch (error) {
      setErrorMessage('Error fetching similar vendors');
      setLoading(false);
    }
  };

  // Call getSimilarVendors when vendorType or city changes
  useEffect(() => {
    if (vendorType && city) {
      getSimilarVendors();
    }
  }, [vendorType, city]);

  // If vendors are still loading, show a loading spinner
  if (loading) {
    return <CircularProgress />;
  }

  // Show error message if there was an issue fetching similar vendors
  if (errorMessage) {
    return <Snackbar open={true} message={errorMessage} />;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Carousel
        dots={true}
        infinite={true}
        speed={500}
        slidesToShow={1} // Show 4 items at once
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2, // Show 2 items on medium screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1, // Show 1 item on small screens
              slidesToScroll: 1,
            },
          },
        ]}
        style={{
          width: '100%', // Ensure carousel takes the full width
          margin: '0 auto', // Center the carousel
        }}
      >
        {similarVendors.map((vendor) => (
          <div key={vendor.vendor_id} style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
            <EFCard
              posts={[vendor]} // Pass each vendor as a single post to the EFCard component
              layout="carousel" // Use the carousel layout for EFCard
            />
          </div>
        ))}
      </Carousel>
    </Box>
  );
};

// Prop validation for SimilarVendorsCarousel component
SimilarVendorsCarousel.propTypes = {
  vendorType: PropTypes.string.isRequired, // vendorType is a required string
  city: PropTypes.string.isRequired, // city is a required string
};

export default SimilarVendorsCarousel;
