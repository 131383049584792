import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods to set data
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useState } from 'react';
import { firestore, storage } from '../firebase'; // Adjust path to your Firebase config
import EFVideoPlayer from './EFVideoPlayer';

interface VideoUploadComponentProps {
  vendorId: string; // Unique vendor ID (e.g., from authentication or props)
}

const EFUploadVideo: React.FC<VideoUploadComponentProps> = ({ vendorId }) => {
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [youtubeLink, setYoutubeLink] = useState<string>('');
  const [error, setError] = useState<string>('');

  // Handle file input change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setVideoFile(file);
    }
  };

  // Upload video to Firebase Storage and store the URL in Firestore
  const handleFileUpload = async () => {
    if (!videoFile) {
      setError('Please select a video file.');
      return;
    }

    setLoading(true);
    const videoRef = ref(storage, `vendors/${vendorId}/videos/${videoFile.name}`);

    try {
      // Upload video to Firebase Storage
      const snapshot = await uploadBytes(videoRef, videoFile);

      // Get the download URL for the uploaded video
      const downloadUrl = await getDownloadURL(snapshot.ref);

      // Store the video URL in Firestore under the vendor's document
      const vendorRef = doc(firestore, 'vendors', vendorId); // Get the vendor's Firestore document
      await setDoc(vendorRef, {
        vendor_video: downloadUrl, // Add or update vendor_video field
      }, { merge: true });

      setVideoUrl(downloadUrl); // Set the uploaded video's URL
      setError('');
    } catch (err) {
      setError('Error uploading video. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Handle YouTube link input change
  const handleYoutubeLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setYoutubeLink(e.target.value);
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {/* YouTube Link Input */}
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="YouTube Video Link"
            value={youtubeLink}
            onChange={handleYoutubeLinkChange}
            fullWidth
            placeholder="Enter YouTube URL"
          />
        </Grid>

        {/* OR Video Upload Section */}
        <Grid item xs={12} sm={12} md={4}>
          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="video-upload"
          />
          <label htmlFor="video-upload">
            <Button className='ef-sm-btn' component="span">
              Select Video
            </Button>
          </label>
        </Grid>

        {/* Upload Button */}
        <Grid item xs={12}>
          <Button
          fullWidth
            className='ef-sm-btn'
            onClick={handleFileUpload}
            disabled={loading || !!youtubeLink}
          >
            {loading ? <CircularProgress size={24} /> : 'Upload Video'}
          </Button>
        </Grid>

        {/* Error Message */}
        {error && (
          <Grid item xs={12}>
            <div style={{ color: 'red' }}>{error}</div>
          </Grid>
        )}
      </Grid>

      {/* Video Player Section */}
      {videoUrl && !youtubeLink && <EFVideoPlayer videoUrl={videoUrl} />}
      {youtubeLink && <EFVideoPlayer videoUrl={youtubeLink} />}
    </div>
  );
};

export default EFUploadVideo;
