import { FaBuilding, FaCocktail, FaCogs, FaGifts, FaPaintBrush, FaPizzaSlice, FaPlus, FaShieldAlt } from 'react-icons/fa'; // Import icons

// MUI Components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKTypography from '../../../../components/MKTypography'; //MKTypography is in the components folder

const WhatWereLookingFor = () => {
  return (
    <>
      {/* Title: Centered */}
      <MKTypography variant="h3" sx={{ mb: 4, textAlign: 'center', fontWeight: 600 }}>
        What We&apos;re Looking For
      </MKTypography>

      <Grid container spacing={3} justifyContent="center">
        {/* Catering / Bakery */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaPizzaSlice size={40} color="orange" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Caterers/Bakers</MKTypography>
          </Card>
        </Grid>

        {/* Bartending Services */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaCocktail size={40} color="purple" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Bartending Services</MKTypography>
          </Card>
        </Grid>

        {/* Security Services */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaShieldAlt size={40} color="blue" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Security Services</MKTypography>
          </Card>
        </Grid>

        {/* Coat Check */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaGifts size={40} color="pink" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Staffed Coat Check Services</MKTypography>
          </Card>
        </Grid>

        {/* Event Insurance */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaCogs size={40} color="green" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Event Insurance</MKTypography>
          </Card>
        </Grid>

        {/* Waste Management */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaBuilding size={40} color="brown" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Waste Management</MKTypography>
          </Card>
        </Grid>

        {/* Gift/Favor Suppliers */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaGifts size={40} color="red" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Gift/Favor Suppliers</MKTypography>
          </Card>
        </Grid>

        {/* Designers */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaPaintBrush size={40} color="cyan" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Designers</MKTypography>
          </Card>
        </Grid>

        {/* Venues */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ p: 2, textAlign: 'center' }}>
            <FaBuilding size={40} color="gold" />
            <MKTypography variant="h6" mt={2} color="textPrimary">Venues</MKTypography>
          </Card>
        </Grid>

        {/* And More */}
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Card sx={{ p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FaPlus size={40} color="teal" />
            <MKTypography variant="h6" mt={2} color="textPrimary" sx={{ ml: 1 }}>
              And More...
            </MKTypography>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default WhatWereLookingFor;
