

// @mui material components
import Icon from "@mui/material/Icon";

import { auth } from "./firebase";
// @mui icons

// Pages
import MyEvents from "./pages/Users/Events/MyEvents";

// Sections
import CommunityEvents from "./pages/Community/Events";
import Community from "./pages/Community/Forum";
import CreateEvent from "./pages/Users/Events/CreateEvent";
import UserSettings from "./pages/Users/Settings";
import AdminDashboard from "./pages/Vendors/Dashboard";
import InventoryPage from "./pages/Vendors/Inventory";
import VendorRegistration from "./pages/Vendors/Registration/VendorRegistration";

const routes = [
  {
    name: "Explore",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Planner",
        collapse: [
       
          {
            name: "Become a Vendor",
            route: "/become-a-vendor",
            component: <VendorRegistration/>,
          }, 
          {
            name: "create event",
            route: "/createnewevent",
            component: <CreateEvent />,
          }, 
          {
            name: "my events",
            route: "/events",
            component: <MyEvents />,
          },       
        
         
          {
            name: "account",
            route: '/accountsettings',
            component: <UserSettings />,
          },
       /* krys    {
            name: "author",
            route: "/pages/landing-pages/author",
            component: <Author />,
          },  */
          //*/
        
        ],
      },
/*       {
        name: "for vendors",
        collapse: [
      
          {
            name: "Become a Vendor",
            route: "/pages/landing-pages/about-us",
            component: <AboutUs />,
          },
        ],
      }, */
    ],
  },
  {
    name: "Community",
    icon: <Icon>article</Icon>,
    collapse: [
      
      {
        name: "Events",
        route: `/communityevents`,
        component: <CommunityEvents/>,
      },
      {
        name: "Threads",
        route: "/communitythreads",
        component: <Community/>,
      }, 
  /*     {
        name: "Non Profits",
        route: `/communityevents`,
        component: <InventoryPage/>,
      }, */

    
    ],
  },

  
];


const vendorRoutes = [
  /*
  {
    name: "Explore",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Testing",
        collapse: [
       
          {
            name: "contact us",
            route: "/pages/landing-pages/contact-us",
            component: <ContactUs />,
          },
       /* krys    {
            name: "author",
            route: "/pages/landing-pages/author",
            component: <Author />,
          },  
          //
        
        ],
      },

    ],
  },
  */
  {
    name: "Plan",
    icon: <Icon>view_day</Icon>,
    collapse: [
    
      {
        name: "create event",
        route: "/createnewevent",
        component: <CreateEvent />,
      }, 
      {
        name: "my events",
        route: "/events",
        component: <MyEvents />,
      },       
    
     
      {
        name: "account",
        route: '/accountsettings',
        component: <UserSettings />,
      },


    ],
  },
  {
    name: "Manage",
    icon: <Icon>article</Icon>,
    collapse: [
      
   
      {
        name: "Become a Vendor",
        route: "/become-a-vendor",
        component: <VendorRegistration/>,
      }, 
      {
        name: "Dashboard",
        route: `/vendor/dashboard`,
        component: <AdminDashboard/>,
      },  
      {
        name: "Inventory",
        route: `/vendor/${auth.currentUser?.uid}/inventory`,
        component: <InventoryPage/>,
      },
  

    
    ],
  },  {
    name: "Community",
    icon: <Icon>article</Icon>,
    collapse: [
      
      {
        name: "Events",
        route: `/communityevents`,
        component: <CommunityEvents/>,
      },
      {
        name: "Forum",
        route: "/communitythreads",
        component: <Community/>,
      }, 
  /*     {
        name: "Non Profits",
        route: `/communityevents`,
        component: <InventoryPage/>,
      }, */

    
    ],
  },
];



export { routes, vendorRoutes };

