import { Grid, Typography } from "@mui/material";
import headerImage from "../../../assets/images/2025eventflowbg.png";
import RotatingCard from "../../../examples/Cards/RotatingCard";
import RotatingCardBack from "../../../examples/Cards/RotatingCard/RotatingCardBack";
import RotatingCardFront from "../../../examples/Cards/RotatingCard/RotatingCardFront";

function FeaturedBlurbs() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{ textAlign: "center", padding: 3 }} // Added padding for spacing
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" }, // Responsive heading size
          }}
        >
          Plan. Organize. Celebrate.
        </Typography>
      </Grid>

      {/* Rotating Cards Section */}
      <Grid container item spacing={3} justifyContent="center" xs={12}>
        {/* Each RotatingCard */}
        <Grid item xs={6} sm={6} md={3}>
          <RotatingCard sx={{ height: '100vh' }}> {/* Ensures full height */}
            <RotatingCardFront
              image="https://www.shutterstock.com/image-photo/picture-black-female-dj-smiling-600nw-2475368343.jpg"
              icon="checklist"
              title="Easy Planning"
              description=""
            />
            <RotatingCardBack
              image={headerImage}
              title=""
              description="Manage guest list, schedule and budget all in one place!"
              action={{
                type: "internal",
                route: "/createnewevent",
                label: "plan now",
              }}
            />
          </RotatingCard>
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <RotatingCard sx={{ height: '100vh' }}> {/* Ensures full height */}
            <RotatingCardFront
              image="https://media.istockphoto.com/id/1399000012/photo/guests-throwing-confetti-over-bride-and-groom-as-they-walk-past-after-their-wedding-ceremony.jpg?s=612x612&w=0&k=20&c=_KRu-9eoSqH8yazsoIDYy15NEzSOTTJQRJ0I7IJTMGA="
              icon="timer"
              title="Find Events"
              description=""
            />
            <RotatingCardBack
              image={headerImage}
              title=""
              description="Find events in your community, easily!"
              action={{
                type: "internal",
                route: "/communityevents",
                label: "community events",
              }}
            />
          </RotatingCard>
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <RotatingCard sx={{ height: '100vh' }}> {/* Ensures full height */}
            <RotatingCardFront
              image="https://media.istockphoto.com/id/590034882/photo/restaurant-table-with-food.jpg?s=612x612&w=0&k=20&c=ZgVIAKS1s10FiQBLvmmgHXSwoLvMHWa7K4Tla8JZcmI="
              icon="edit_calendar"
              title="Easy Booking"
              description=""
            />
            <RotatingCardBack
              image={headerImage}
              title=""
              description="Browse and book venues, caterers, and other vendors directly through our site!"
              action={{
                type: "internal",
                route: "/welcometoeventflow",
                label: "book a vendor",
              }}
            />
          </RotatingCard>
        </Grid>

        <Grid item xs={6} sm={6} md={3}>
          <RotatingCard sx={{ height: '100vh'  }}> {/* Ensures full height */}
            <RotatingCardFront
              image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy7r7QPrtVq-XN1WvnCzaRG12NgzNMeIPhdw&s"
              icon="emoji_people"
              title="Join Now"
              description=""

            />
            <RotatingCardBack
              image={headerImage}
              title=""
              description="Join Eventflow now!"
              action={{
                type: "internal",
                route: "/welcometoeventflow",
                label: "join for free",
              }}
            />
          </RotatingCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FeaturedBlurbs;
