import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating, TextField } from "@mui/material";
import { FC } from 'react';

interface ReviewDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  value?: number | null;
  setValue?: (value: number | null) => void;
  reviewText?: string;
  setReviewText?: (text: string) => void;
  labels?: { [index: string]: string };
  getLabelText?: (value: number) => string;
  authUserId?: string | null | undefined; // to check if user is signed in
}

const ReviewDialog: FC<ReviewDialogProps> = ({
  open,
  handleClose,
  handleSubmit,
  value,
  setValue,
  reviewText,
  setReviewText,
  labels,
  getLabelText,
  authUserId
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Submit a Review</DialogTitle>
      <DialogContent>
        <Box sx={{ width: 300, display: 'flex', alignItems: 'center', mb: 2 }}>
          <Rating
            value={value}
            precision={1}
            onChange={(event, newValue) => setValue(newValue)}
            getLabelText={getLabelText}
          />
          {value !== null && (
            <Box sx={{ ml: 2 }}>{labels[value]}</Box>
          )}
        </Box>
        <TextField
          autoFocus
          margin="dense"
          id="reviewText"
          label="Review"
          type="text"
          multiline
          rows={5}
          fullWidth
          variant="outlined"
          value={reviewText}
          onChange={(e) => setReviewText(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {authUserId != null ? (
          <Button onClick={handleSubmit}>Submit</Button>
        ) : (
          <p>You must sign in to leave a review</p>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ReviewDialog;
