import { Box, Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { arrayRemove, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import defaultImage from "../../../../assets/images/2025eventflowbg.png";
import EFCart from '../../../../components/EFCart';
import { businessTypeDropdown } from '../../../../ef-constants';
import { auth, firestore } from "../../../../firebase";
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";
import EditEvent from './components/EditEvent';
import EFCheckInButton from './components/EFCheckInButton';
import EFDashboardButton from './components/EFDashboardButton';
import EventDetailsInfo from './components/EventDetailsInfo';
import Itinerary from './components/Itinerary';

function EventDetails() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [daysLeft, setDaysLeft] = useState(null); // <-- Define state for days left

  const [guestList, setGuestList] = useState([]);
  const [newGuestName, setNewGuestName] = useState("");
  const [editingGuestIndex, setEditingGuestIndex] = useState(null);
  const [openVendorModal, setOpenVendorModal] = useState(false);  // Modal control state
  const [openEditDescriptionModal, setOpenEditDescriptionModal] = useState(false);  // Modal for event description editing
  const [editableEventName, setEditableEventName] = useState(event?.event_name || "");
const [editableEventDescription, setEditableEventDescription] = useState(event?.event_description || "");
const [openEditEventDialog, setOpenEditEventDialog] = useState(false); // To open/close the event edit dialog
const [editableEventDate, setEditableEventDate] = useState("");
const [editableEventBudget, setEditableEventBudget] = useState("");
const [editableEventAddress, setEditableEventAddress] = useState("");
const [editableEventCapacity, setEditableEventCapacity] = useState("");
const [editableEventCity, setEditableEventCity] = useState("");
const [editableEventImage, setEditableEventImage] = useState("");
const [editableEventPublic, setEditableEventPublic] = useState(false); // To handle public event
const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Add this state for delete confirmation dialog



  const [eventDescription, setEventDescription] = useState("");  // State to manage event description
  const [inviteSent, setInviteSent] = useState({});  // Track invite status


  useEffect(() => {
    
    console.log('fetching event with event id ', eventId)
    if (!eventId) {
      setError("Event ID is required");
      return;
    }
     
     const setEventPage = async () => {
  
       console.log("setting event page");
       const docRef = doc(firestore, "Events", eventId);
       const docSnap = await getDoc(docRef);
 
       if (docSnap.exists()) {
         const result = docSnap.data();
         //console.log(result);
         setEvent(result);
         setEventDescription(result.event_description || "");
         setEditableEventName(result.event_name || "");
         setEditableEventDate(result.event_date || "");
         setEditableEventBudget(result.event_budget || "");
         setEditableEventCapacity(result.event_capacity || "");
         setEditableEventCity(result.event_city || "");
         setEditableEventAddress(result.event_address || "");
         setEditableEventImage(result.event_image || "");
         setGuestList(result.guests || []);
         setEditableEventPublic(result.public || false); // Set public status
 
           // Calculate days left based on event date
           const eventDate = new Date(result.event_date); // Convert event date to Date object
           const currentDate = new Date(); // Get the current date
           const timeDiff = eventDate - currentDate; // Time difference in milliseconds
           const daysRemaining = Math.floor(timeDiff / (1000 * 3600 * 24)); // Convert ms to days
   
           setDaysLeft(daysRemaining); // Update the state with the days left
 
         setLoading(false);
       } else {
         console.log("No such document!");
         setLoading(false);
         setError("Event not found.");
       }
     };
 
    
     setEventPage();
     console.log("use effect complete");
   }, [eventId]);

  // Fetch vendors from the "Vendors" sub-collection
  const fetchVendors = async () => {
    if (!eventId) return;

    const vendorCollectionRef = collection(firestore, "Events", eventId, "Vendors");
    const vendorSnapshot = await getDocs(vendorCollectionRef);
    const vendorIds = vendorSnapshot.docs.map(doc => doc.data().vendor_id);

    if (vendorIds.length === 0) return;

    const vendorDetails = [];
    for (const vendorId of vendorIds) {
      const vendorDocRef = doc(firestore, "vendors", vendorId);
      const vendorDocSnap = await getDoc(vendorDocRef);

      if (vendorDocSnap.exists()) {
        vendorDetails.push({ id: vendorDocSnap.id, ...vendorDocSnap.data() });
      }
    }

    setVendorList(vendorDetails);
  };

  //  Add to google calendar
  const addToGoogleCalendar = () => {
    const googleCalendarBaseURL = 'https://www.google.com/calendar/render';
    const eventDetails = {
      text: event.event_name, // Event name
      dates: `${new Date(event.event_date).toISOString().replace(/-|:|\.\d+/g, '')}/${new Date(event.event_date).toISOString().replace(/-|:|\.\d+/g, '')}`,
      details: event.event_description, // Event description
      location: event.event_address, // Event location
      // Optionally you can add more details like event duration, reminders, etc.
    };
  
    const googleCalendarURL = `${googleCalendarBaseURL}?action=TEMPLATE&text=${encodeURIComponent(eventDetails.text)}&dates=${eventDetails.dates}&details=${encodeURIComponent(eventDetails.details)}&location=${encodeURIComponent(eventDetails.location)}`;
    
    window.open(googleCalendarURL, '_blank');
  };


  // Remove Vendor from Event
  const handleRemoveVendor = async (vendorId) => {
    try {
      // Remove the vendor from the event's vendors sub-collection
      const eventDocRef = doc(firestore, "Events", eventId);
      await updateDoc(eventDocRef, {
        vendors: arrayRemove(vendorId),  // Remove vendor ID from event vendors list
      });

      // Update the vendor list in state
      setVendorList(vendorList.filter((vendor) => vendor.id !== vendorId));
    } catch (error) {
      console.error("Error removing vendor: ", error);
    }
  };


  // Handle adding a new guest to the event
  const handleAddGuest = async () => {
    if (newGuestName.trim() === "") {
      alert("Please enter a valid guest name.");
      return;
    }

    const updatedGuestList = [...guestList, newGuestName];
    try {
      await updateDoc(doc(firestore, "Events", eventId), {
        guests: updatedGuestList,
      });

      setGuestList(updatedGuestList);  // Update local state
      setNewGuestName("");  // Clear input field
    } catch (error) {
      console.error("Error adding guest: ", error);
    }
  };

  const handleDeleteGuest = async (guestName) => {
    const updatedGuestList = guestList.filter(guest => guest !== guestName);

    try {
      await updateDoc(doc(firestore, "Events", eventId), {
        guests: updatedGuestList,
      });

      setGuestList(updatedGuestList);  // Update local state
    } catch (error) {
      console.error("Error deleting guest: ", error);
    }
  };

  const handleEditGuest = (index) => {
    setEditingGuestIndex(index);
    setNewGuestName(guestList[index]);
  };

  const handleSaveGuestEdit = async () => {
    if (newGuestName.trim() === "") {
      alert("Please enter a valid guest name.");
      return;
    }

    const updatedGuestList = guestList.map((guest, index) => (index === editingGuestIndex ? newGuestName : guest));

    try {
      await updateDoc(doc(firestore, "Events", eventId), {
        guests: updatedGuestList,
      });

      setGuestList(updatedGuestList);  // Update local state
      setNewGuestName("");  // Clear input field
      setEditingGuestIndex(null);  // Clear editing index
    } catch (error) {
      console.error("Error editing guest: ", error);
    }
  };

  const handleSendInvite = (guestName) => {
    setInviteSent((prev) => ({ ...prev, [guestName]: true }));

    // Logic to send an email or notification here.
    alert(`Invite sent to ${guestName}`);
  };


  const handleDeleteEvent = async () => {
    try {
      const eventDocRef = doc(firestore, "Events", eventId);
      await deleteDoc(eventDocRef);  // Delete the event document from Firestore
  
      // Optionally, remove all associated vendors and guests or handle any necessary cleanup.
      
      // Navigate back to the event list or home page after deleting
      navigate("/events");
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };
  

  const getDaysLeft = (eventDate) => {
    const today = new Date();
    const eventDateObj = new Date(eventDate); // Ensure eventDate is a Date object

    if (isNaN(eventDateObj)) {
      console.error("Invalid event date:", eventDate);
      return "Invalid Date";
    }

    const timeDiff = eventDateObj - today;
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert ms to days
    return daysLeft >= 0 ? daysLeft : 0;  // Ensure it's not negative
  };

  //const daysLeft = event?.event_date ? getDaysLeft(event?.event_date) : 0;

  const remainingCapacity = event ? event.event_capacity - guestList.length : 0;

  const groupedVendors = businessTypeDropdown.reduce((acc, type) => {
    acc[type] = vendorList.filter(vendor => vendor.vendor_type === type);
    return acc;
  }, {});

 

  const isOwner = event?.ownerId === auth.currentUser?.uid;


  const handleSaveEvent = (updatedEvent) => {
    // Handle saving the updated event (e.g., sending to Firestore)
    console.log('Updated Event:', updatedEvent);
    //setEvent(updatedEvent);  // Optionally update the parent state with the new data
  };

  const handleCancelEventEdit = () => {
    // Logic to handle canceling the edit (optional)
    console.log('Event edit canceled');
  };

  

  useEffect(() => {
    if (event) {
      fetchVendors();
    }
  }, [event]);  // Dependency array ensures this effect runs only when `event` is updated


  
  useEffect(() => {
    if (event && event.event_date) {
      // Calculate and set days left whenever event data or event date changes
      const days = getDaysLeft(event.event_date);
      setDaysLeft(days);
    }
  }, [event]);  // Dependency array ensures this effect runs when the `event` object changes

  

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error}</Typography>;

  return (
    <BaseLayout
      title="Events"
      breadcrumb={[
        { label: "Events", route: "/events" },
        { label: "Event Details", route: `/events/${eventId}` },
      ]}
    >

<Box sx={{ backgroundColor: '#F2F2F2', boxShadow: 3, p: 3, mx: 'auto', width: { xs: '100%', sm: '100%', md: '100%' } }}>


{isOwner && (
                  <EFDashboardButton eventId={eventId} variant="contained" size="large" fullWidth />


        )}
      {/* Banner with title overlay */}
      {event.event_image ? (
              <Box 
              sx={{
                position: 'relative', 
                height: '400px', 
                backgroundImage: `url(${event.event_image})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center', 
                marginBottom: 3,
                marginTop: 5
              }}
            >
              {/* Overlay Title */}
              <Box 
                sx={{
                  position: 'absolute', 
                  bottom: '70px', 
                  left: '20px', 
                  color: 'white', 
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                  padding: '10px 20px',
                  borderRadius: '5px'
                }}
              >
                <Typography variant="h4">{event.event_name}</Typography>
              </Box>              
              {/* Days Left */}
              <Box 
                sx={{
                  position: 'absolute', 
                  bottom: '20px', 
                  left: '20px', 
                  color: 'white', 
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                  padding: '10px 20px',
                  borderRadius: '5px'
                }}
              >
  <Typography variant="h6">
          {daysLeft !== null ? daysLeft : 'Calculating...'} Days Left
        </Typography>              </Box>


            </Box>
      ):(
        <Box 
        sx={{
          position: 'relative', 
          height: '400px', 
          backgroundImage: `url(${defaultImage})`, 
          backgroundSize: 'cover', 
          backgroundPosition: 'center', 
          marginBottom: 3
        }}
      >
        {/* Overlay Title */}
        <Box 
          sx={{
            position: 'absolute', 
            bottom: '20px', 
            left: '20px', 
            color: 'white', 
            backgroundColor: 'rgba(0, 0, 0, 0.5)', 
            padding: '10px 20px',
            borderRadius: '5px'
          }}
        >
          <Typography variant="h4">{event.event_name}</Typography>
        </Box>
      </Box>

      )}


      <EventDetailsInfo budget={Number(event.event_budget)} capacity={event.event_capacity} city={event.event_city} date={event.event_date} />
      <Grid container padding={5}>
        
        <Grid item> 
        {(event.event_address) && (

        <h4>Address: {event.event_address}</h4>
        )}
        <Typography variant="body1">{event.event_description}</Typography>
{/*         <Button
          variant="outlined"
          color="primary"
          className='ef-sm-btn'
          sx={{ marginTop: 2 }}
          onClick={handleOpenEditDescriptionModal}
        >
          Edit Description
        </Button> */}
        </Grid>
    
    { event.event_checkin && (
        <EFCheckInButton eventId={eventId} variant="contained" size="large" fullWidth />

    )}
       

      </Grid>

      <Itinerary vendors={vendorList} owner={isOwner} event_date={event.event_date} eventId={eventId}/>

      {event.event_ticketed && (
      <Button
           variant="outlined"
           color="primary"
           fullWidth
          onClick={() => setOpenVendorModal(true)}
          className='ef-btn orange'
        >
          Buy Tickets
        </Button>
      )}
      <Button
    className='ef-sm-btn'
fullWidth
  onClick={addToGoogleCalendar}
  sx={{ marginTop: 2 }}
>
  Add to Google Calendar
</Button>


      {/* <EventComments eventId={eventId} authID={auth.currentUser.uid}/> */}

      {/* Guest List Management */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h5">Guests</Typography>
        <List>
          {guestList.map((guest, index) => (
            <ListItem key={index}>
              <ListItemText primary={guest} />
              <Button onClick={() => handleDeleteGuest(guest)}>Delete</Button>
              <Button onClick={() => handleEditGuest(index)}>Edit</Button>
              <Button onClick={() => handleSendInvite(guest)}>Send Invite</Button>
            </ListItem>
          ))}
        </List>
        {isOwner && (
  <>
        <TextField
          value={newGuestName}
          onChange={(e) => setNewGuestName(e.target.value)}
          label="Add Guest"
         
        />
        <Button  variant="outlined"
          color="primary"  className='ef-sm-btn' onClick={handleAddGuest}>Add</Button>
          </>
        )}
      </Box>
  {/* Vendor List Section */}
  <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" component="div" gutterBottom>
          Vendor List
        </Typography>
        <Button
           variant="outlined"
           color="primary"
          onClick={() => setOpenVendorModal(true)}
          className='ef-sm-btn'
        >
          View Vendors
        </Button>
<br></br>
{isOwner && (
  <>
  <div>

 <EditEvent
        eventId={eventId}
   eventData={event}
        onSave={handleSaveEvent}
        onCancel={handleCancelEventEdit}
      />
        <Button
  variant="outlined"
  className='delete'
  onClick={() => setOpenDeleteDialog(true)}
  sx={{ marginTop: 2}}
>
  Delete Event
</Button>
</div>
<br></br>
<EFCart />

</>
)}


      {/* Delete Event Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Event</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this event? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteEvent} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

        {/* Vendor Modal */}
        <Dialog open={openVendorModal} onClose={() => setOpenVendorModal(false)}>
          <DialogTitle>Vendors</DialogTitle>
          <DialogContent>
            {businessTypeDropdown.map((type) => (
              <Box key={type} sx={{ marginTop: 3 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  {type}
                </Typography>
                <List sx={{ marginTop: 2 }}>
                  {groupedVendors[type]?.length > 0 ? (
                    groupedVendors[type].map((vendor) => (
                      <ListItem key={vendor.id}>
                         {vendor.vendor_pictureUrl ? (
                      <CardMedia
                        component="img"
                        alt={vendor.vendor_name}
                        image={vendor.vendor_pictureUrl}
                        sx={{
                          width: 90,
                          height: 100,
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 90,
                          height: 100,
                          backgroundColor: '#f5f5f5',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          color: '#aaa',
                        }}
                      >
                        No Image
                      </Box>
                    )}
                        <ListItemText primary={vendor.vendor_name} />
                        <Box sx={{width: '25%', margin: '1px'}}>
                                               {/* Visit Page Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        className='ef-sm-btn'
                        onClick={() => navigate(`/vendor/${vendor.vendor_id}`)}
                        sx={{
                          color: '#fff',
                          width: '100%', // Make the button take up full width
                        }}
                      >
                        Visit 
                      </Button>

                      {/* Link/Button to Vendor's Inventory Page */}
                      <Button
                        variant="contained"
                        color="info"
                        className='ef-sm-btn'
                        onClick={() => navigate(`/vendor/${vendor.vendor_id}/inventory`)}  // Correct function syntax
                        sx={{ width: '100%' }}
                      >
                        View Offerings
                      </Button>

                          <Button
                            className='delete'
                            onClick={() => handleRemoveVendor(vendor.id)}
                            sx={{ width: '100%' }}
                          >
                            Remove 
                          </Button>
                        </Box>
                      </ListItem>
                    ))
                  ) : (
                    <Typography>No vendors for this type yet.</Typography>
                  )}
                </List>
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenVendorModal(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>


      {isOwner && (
        <>
           {/* Floating button to browse vendors for this event */}
           <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          width: 'auto',
          height: 48,
          padding: '0 16px',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => navigate(`/vendors/${event.event_city}`)}
      >
        Browse Vendors for {event.event_name}
      </Fab>
      </>
      )}
</Box>
    </BaseLayout>
  );
}

export default EventDetails;
