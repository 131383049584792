import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import Cookies from "js-cookie"; // Import js-cookie to handle cookies
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { auth, firestore } from "../../../firebase";
import BaseLayout from "../../../layouts/sections/components/BaseLayout";

const AdminDashboard = () => {
  const [userExists, setUserExists] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const goToSettings = () => {
    navigate(`/vendor/vendorsettings`);

  };

  const goToBookings = () => {
    navigate(`/managebookings`);

  }; 

  const goToInventory = () => {
    navigate(`/vendor/${Cookies.get("userId")}/inventory`);

  }; 

  useEffect(() => {
    const checkUser = async () => {
      // First, try to get the user from cookies as a fallback
      const cookieUserId = Cookies.get("userId"); // Ensure consistent cookie naming
      const cookieIsVendor = Cookies.get("isVendor");

      if (cookieUserId && cookieIsVendor !== undefined) {
        // If user info is available in cookies, use that
        console.log("User from cookies:", cookieUserId);
        setUserExists(true);
        setIsVendor(cookieIsVendor === "true"); // Convert the cookie value to a boolean
        setLoading(false);
      } else {
        // If no user info in cookies, fallback to Firebase Auth
        const user = auth.currentUser;

        if (user) {
          try {
            const userDocRef = doc(firestore, "users", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
              const userData = userDoc.data();
              console.log("User from Firestore:", userData);
              setUserExists(true);
              setIsVendor(userData.isVendor || false);

              // Store the user info in cookies for future use
              Cookies.set("userId", user.uid, { expires: 7 }); // Expires in 7 days
              Cookies.set("isVendor", userData.isVendor ? "true" : "false", { expires: 7 });
            } else {
              console.log("No user document found.");
              setUserExists(false);
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
            setUserExists(false);
          }
        } else {
          console.log("No Firebase user found.");
          setUserExists(false);
        }
        setLoading(false);
      }
    };

    checkUser();
  }, []);

  if (loading) {
    return (
      <BaseLayout title="Admin Dashboard" breadcrumb={[{ label: "Loading..." }]}>
        <CircularProgress />
      </BaseLayout>
    );
  }

  if (!userExists) {
    return (
      <BaseLayout title="Admin Dashboard" breadcrumb={[{ label: "Not Authorized" }]}>
        <Box sx={{ padding: 3 }}>
          <Typography variant="h4" color="error" gutterBottom>
            User Not Found
          </Typography>
          <Typography variant="body1">
            You are not authenticated. Please log in to continue.
          </Typography>
        </Box>
      </BaseLayout>
    );
  }

  if (!isVendor) {
    return (
      <BaseLayout title="Admin Dashboard" breadcrumb={[{ label: "Not Authorized" }]}>
        <Box sx={{ padding: 3 }}>
          <Typography variant="h4" color="error" gutterBottom>
            Access Denied
          </Typography>
          <Typography variant="body1">
            You do not have vendor privileges. Please contact an admin.
          </Typography>
        </Box>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout
      title="Admin Dashboard"
      breadcrumb={[
        { label: "Dashboard", route: "/admin/dashboard" },
      ]}
    >
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to the Admin Dashboard
        </Typography>
        <Typography variant="body1">
          You have access as a Vendor. Manage your events and check-ins here.
        </Typography>
    
        <Button
    
      className="ef-sm-btn"
      
      onClick={goToBookings}
    >
      Manage Your Bookings
    </Button>   

        <Button
    
      className="ef-sm-btn"
      
      onClick={goToInventory}
    >
      Manage Your Inventory
    </Button>        
    <Button
    
    className="ef-sm-btn"
    
    onClick={goToSettings}
  >
    Settings
  </Button>

      </Box>
    </BaseLayout>
  );
};

export default AdminDashboard;
