import { Box, Typography } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import EFCard from '../../../components/EFCard'; // Adjust the path to your EFCard component
import { firestore } from '../../../firebase';

interface Vendor {
  vendor_id: string;
  vendor_pictureUrl: string;
  vendor_name: string;
  vendor_type: string;
  vendor_majorCity: string; // Ensure this field is included
}

const FeaturedVendors: React.FC = () => {
  const [featuredVendors, setFeaturedVendors] = useState<Vendor[]>([]);

  useEffect(() => {
    const fetchFeaturedVendors = async () => {
      try {
        const vendorsRef = collection(firestore, 'vendors');
        const q = query(vendorsRef, where('vendor_isFeatured', '==', true));
        const querySnapshot = await getDocs(q);

        const vendors: Vendor[] = [];
        querySnapshot.forEach((doc) => {
          const vendorData = doc.data();
          vendors.push({
            vendor_id: doc.id,
            vendor_pictureUrl: vendorData.vendor_pictureUrl,
            vendor_name: vendorData.vendor_name,
            vendor_type: vendorData.vendor_type,
            vendor_majorCity: vendorData.vendor_majorCity || "Unknown City", // Default to "Unknown City" if not present
          });
        });

        setFeaturedVendors(vendors);
      } catch (error) {
        console.error('Error fetching featured vendors:', error);
      }
    };

    fetchFeaturedVendors();
  }, []);

  return (
    <Box 
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column', // Stack the heading and content vertically
        alignItems: 'center', // Center horizontally
        justifyContent: 'flex-start', // Start from the top of the container
        gap: '20px', // Add some space between the title and content
      }}
    >
      <Typography variant="h4" gutterBottom>
        Featured Vendors
      </Typography>
      {featuredVendors.length > 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <EFCard posts={featuredVendors} layout="grid" displayField="city" />
        </Box>
      ) : (
        <Typography>No featured vendors available.</Typography>
      )}
    </Box>
  );
};

export default FeaturedVendors;
