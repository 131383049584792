import { InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import bgImage from "../../../assets/images/bg1.jpg";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKInput from "../../../components/MKInput";
import { businessTypeDropdown, city_names, states } from "../../../ef-constants";
import { auth, firestore } from '../../../firebase';
import UploadPhoto from "../../../functions/UploadPhoto";


function VendorSettings() {
  const [email, setEmail] = useState('');
  const [tier, setTier] = useState(0);
  const [capacity, setCapacity] = useState('');
  const [tags, setTags] = useState([]); // Initialize tags as an array
  const [addOns, setAddOns] = useState([]); // Initialize addOns as an array
  const [amenties, setAmenties] = useState([]); // Initialize amenities as an array
  const [vendorType, setVendorType] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [loading, setLoading] = useState(true); // Track loading state
  const [majorCity, setMajorCity] = useState('');
  const [picture, setPicture] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [number, setNumber] = useState('');
  const [hours, setHours] = useState('');
  const [docId, setDocID] = useState('');
  const [showUploadPhoto, setShowUploadPhoto] = useState(false); // Control visibility of the UploadPhoto component

  const navigate = useNavigate();

 

  const availableAddOns = ["Set Up", "Lighting", "Bar", "DJ Setup", "Sound System", "Projector"]; // Pre-populated list of add-ons
  const availableAmenties = ["Parking", "Wi-Fi", "Restrooms", "Seating Area", "VIP Area", "TV", "A/C"]; // Pre-populated list of amenities

  const handleTypeChange = (event) => {
    setVendorType(event.target.value);
  };


  const handleStateChange = (event) => {
    setState(event.target.value);
   // console.log('Vendor Type Updated:', event.target.value);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "name":
        setName(value);
        break;
  
          case "city":
            setCity(value);
            break;
            case "zip":
              if (/^\d{0,5}$/.test(value)) {
                setZip(value);
              }
                            break;  
      case "email":
        setEmail(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "website":
        setWebsite(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "phone":
        if (value.length <= 20) {
          setPhone(value);
        }
                break;
      case "number":
        setNumber(value);
        break;
      case "majorCity":
        setMajorCity(value);
        break;
      case "capacity":
        if (/^\d+$/.test(value)) {

        setCapacity(value);
        }
        break;
      case "hours":
        setHours(value);
        break;
      default:
        break;
    }
  };

  const handleTagChange = (e) => {
    const value = e.target.value;
    const tagArray = value.split(',').map(tag => tag.trim()).filter(tag => tag); // Split by commas and trim spaces, then filter out empty values
    setTags(tagArray); // Store tags as an array
  };

  const handleAddOnsChange = (event, newAddOns) => {
    setAddOns(newAddOns); // Store selected add-ons as an array
  };

  const goToProfile = () => {
    navigate(`/vendor/${auth.currentUser?.uid}`);
  };

  const handleAmentiesChange = (event, newAmenties) => {
    setAmenties(newAmenties); // Store selected amenities as an array
  };

  const handleSaveSettings = async () => {
    try {
      const vendorRef = doc(firestore, 'vendors', docId); // Referring to the current vendor document
  
      // Check if the document exists
      const vendorDoc = await getDoc(vendorRef);
  
      // Prepare the vendor data, providing default values for undefined or null fields
      const vendorData = {
        vendor_name: name || '',  // Default to empty string if name is undefined
        vendor_description: description || '',  // Default to empty string if description is undefined
        vendor_email: email || '',
        vendor_type: vendorType || '',
        vendor_capacity: capacity || '',
        vendor_city: city || '',
        vendor_state: state || '',
        vendor_zip: zip || '',
        vendor_location: address || '',
        vendor_number: number || '',
        vendor_hours: hours || '',
        vendor_tags: Array.isArray(tags) && tags.length ? tags : [],  // Ensure tags is an array and not undefined
        vendor_website: website || '',
        vendor_pictureUrl: picture || '',
        vendor_majorCity: majorCity || '',
        vendor_phone: phone || '',
        vendor_addOns: Array.isArray(addOns) && addOns.length ? addOns : [],  // Ensure addOns is an array and not undefined
        vendor_amenities: Array.isArray(amenties) && amenties.length ? amenties : [],  // Ensure amenities is an array and not undefined
      };
  
      console.log('Vendor Data to Save:', vendorData);
  
      if (vendorDoc.exists()) {
        // If the document exists, update it
        await updateDoc(vendorRef, vendorData);
        alert('Settings updated successfully');
      } else {
        // If the document doesn't exist, create a new document
        await setDoc(vendorRef, vendorData);
        alert('Settings saved successfully');
      }
    } catch (e) {
      alert('Error saving settings. Please try again.');
      console.error('Error saving vendor data:', e.message);
    }
  };
  

  useEffect(() => {
    const loadVendorData = async () => {
      setLoading(true); // Set loading to true before data fetch
      try {
        const vendorDocRef = doc(firestore, 'vendors', auth.currentUser.uid);
        const vendorDoc = await getDoc(vendorDocRef);
        if (vendorDoc.exists()) {
          console.log('doc found');
          const vendorData = vendorDoc.data();
          setDocID(vendorDoc.id);
          setName(vendorData.vendor_name);
          setEmail(vendorData.vendor_email);
          setVendorType(vendorData.vendor_type);
          setAddress(vendorData.vendor_location);
          setCity(vendorData.vendor_city);
          setState(vendorData.vendor_state);
          setZip(vendorData.vendor_zip);
          setPhone(vendorData.vendor_phone);
          setDescription(vendorData.vendor_description);
          setWebsite(vendorData.vendor_website);
          setMajorCity(vendorData.vendor_majorCity);
          setCapacity(vendorData.vendor_capacity);
          setNumber(vendorData.vendor_number);
          setPicture(vendorData.vendor_pictureUrl);
          
          // Ensure that tags is an array
          setTags(Array.isArray(vendorData.vendor_tags) ? vendorData.vendor_tags : vendorData.vendor_tags?.split(',').map(tag => tag.trim()));
  
          setPicture(vendorData.vendor_pictureUrl);
          setHours(vendorData.vendor_hours);
  
          // Handle addOns and amenties as arrays
          setAddOns(vendorData.vendor_addOns || []);
          setAmenties(vendorData.vendor_amenties || []);
          // Set other vendor data...
          setLoading(false); // Set loading to false after data is loaded
        } else {
          console.log("Vendor data not found.");
          setLoading(false); // Set loading to false if document doesn't exist
        }
      } catch (e) {
        console.error("Error fetching vendor data:", e);
        setLoading(false);
      }
    };
  
    loadVendorData();
  }, []); // Effect will run once on mount
/*
  useEffect(() => {
    // Load current user and vendor data from Firestore
    const loadVendorData = async () => {
      const vendorDocRef = doc(firestore, 'vendors', auth.currentUser.uid);
      const vendorDoc = await getDoc(vendorDocRef); // Use getDoc to fetch the document data
      console.log('vendor page');

      if (vendorDoc.exists()) {
        const vendorData = vendorDoc.data();
        setDocID(vendorDoc.id);
        setName(vendorData.vendor_name);
        setEmail(vendorData.vendor_email);
        setVendorType(vendorData.vendor_type);
        setAddress(vendorData.vendor_location);
        setCity(vendorData.vendor_city);
        setState(vendorData.vendor_state);
        setZip(vendorData.vendor_zip);
        setPhone(vendorData.vendor_phone);
        setDescription(vendorData.vendor_description);
        setWebsite(vendorData.vendor_website);
        setMajorCity(vendorData.vendor_majorCity);
        setCapacity(vendorData.vendor_capacity);
        setNumber(vendorData.vendor_number);
        setPicture(vendorData.vendor_pictureUrl);
        
        // Ensure that tags is an array
        setTags(Array.isArray(vendorData.vendor_tags) ? vendorData.vendor_tags : vendorData.vendor_tags?.split(',').map(tag => tag.trim()));

        setPicture(vendorData.vendor_pictureUrl);
        setHours(vendorData.vendor_hours);

        // Handle addOns and amenties as arrays
        setAddOns(vendorData.vendor_addOns || []);
        setAmenties(vendorData.vendor_amenties || []);
      }
    };

    if (auth.currentUser) {
      loadVendorData();
    }
  }, []);
  */

  return (
    <MKBox
      position="fixed"
      top={0}
      left={0}
      zIndex={1}
      width="100%"
      minHeight="100vh"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
        `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={10} lg={10} xl={10} mt={15} mb={10}>
            <Card>
              <MKBox
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Vendor Settings
                </Typography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <MKBox component="form" role="form">
                  {/* Vendor Form Fields */}
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      id="email"
                      label="Email"
                      value={email}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      id="name"
                      label="Business Name"
                      value={name}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      id="address"
                      label="Address"
                      value={address}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                      <MKInput id="city" label="City" value={city} onChange={handleInputChange} fullWidth />
                    </MKBox>    
                   
                    <MKBox mb={2}>
                      <InputLabel>State</InputLabel>
                      <Select
                        labelId="State"
                        id="state"
                        value={state}
                        onChange={handleStateChange}
                        fullWidth
                      >
                        {states.map((x) => (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput id="zip" value={zip} label="Zip Code" onChange={handleInputChange} fullWidth />
                    </MKBox>

                  <MKBox mb={2}>
                    <Autocomplete
                      options={city_names}
                      value={majorCity}
                      onChange={(event, value) => setMajorCity(value)}
                      renderInput={(params) => <TextField {...params} label="Select Major City" />}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput
                      id="description"
                      label="Description"
                      multiline
                      rows={4}
                      value={description}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <InputLabel>Business Type</InputLabel>
                    <Select
                      labelId="vendorType"
                      id="vendorType"
                      value={vendorType}
                      onChange={handleTypeChange}
                      fullWidth
                    >
                      {businessTypeDropdown.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput
                      id="phone"
                      label="Phone Number"
                      value={number}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput
                      id="website"
                      label="Website"
                      value={website}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>

                  {vendorType === 'Transportation' || vendorType === 'Venue' ? (
                    <MKBox mb={2}>
                      <MKInput
                        id="capacity"
                        label="Capacity"
                        value={capacity}
                        onChange={handleInputChange}
                        fullWidth
                      />
                    </MKBox>
                  ) : null}

                  <MKBox mb={2}>
                    <MKInput
                      id="hours"
                      label="Available Hours"
                      value={hours}
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <Autocomplete
                      multiple
                      options={availableAddOns}
                      value={addOns}
                      freeSolo
                      onChange={handleAddOnsChange}
                      renderInput={(params) => <TextField {...params} label="Add Ons" />}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <Autocomplete
                      multiple
                      options={availableAmenties}
                      value={amenties}
                      freeSolo
                      onChange={handleAmentiesChange}
                      renderInput={(params) => <TextField {...params} label="Amenities" />}
                    />
                  </MKBox>

            {/*       <MKBox mb={2}>
                    <MKInput
                      id="tags"
                      label="Tags (comma separated)"
                      value={tags.join(", ")} // Display tags as a comma-separated string
                      onChange={handleTagChange}
                      fullWidth
                    />
                  </MKBox> */}

                  {/* Avatar Section */}
                  <MKBox mb={2} display="flex" justifyContent="center" alignItems="center">
                    {picture ? (
                      <>
                        <img
                          src={picture} // Show the vendor's uploaded picture
                          alt="Vendor Profile"
                          style={{
                            width: '100px',
                            height: '100px',
                            borderRadius: '50%',
                            objectFit: 'cover',
                            marginBottom: '20px',
                          }}
                        />
                        <MKButton
                          variant="contained"
                          onClick={() => setShowUploadPhoto(true)} // Trigger showing the UploadPhoto component
                          sx={{ marginTop: '10px', marginLeft: '10px' }}
                        >
                          Change Avatar
                        </MKButton>
                      </>
                    ) : (
                      <MKButton
                        variant="contained"
                        onClick={() => setShowUploadPhoto(true)} // Trigger showing the UploadPhoto component
                        sx={{ marginTop: '10px', marginLeft: '10px' }}
                      >
                        Upload Avatar
                      </MKButton>
                    )}
                  </MKBox>

                  {/* Upload Photo Component */}
                  {showUploadPhoto && (
                    <UploadPhoto
                      vendorId={auth.currentUser?.uid}
                      source="vendors"
                      onUploadComplete={(url) => {
                        setPicture(url); // Update picture with the uploaded URL
                        setShowUploadPhoto(false); // Hide the upload component after upload
                      }}
                    />
                  )}

         <MKBox display="flex" justifyContent="flex-end" mt={3} mb={5}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      className='ef-btn'
                      onClick={goToProfile}
                    >
                      Visit Profile
                    </MKButton>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSaveSettings}
                    >
                      Save Settings
                    </MKButton>
                  </MKBox>

                  {/* Save Button */}
                  <MKBox display="flex" justifyContent="flex-end" mt={3}>
                
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default VendorSettings;