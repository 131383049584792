import { useMediaQuery } from "@mui/material"; // Importing useMediaQuery for responsive design
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // For page navigation

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

// Eventflow.app React components
import { doc, setDoc } from "firebase/firestore";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKInput from "../../../components/MKInput";
import MKTypography from "../../../components/MKTypography";
import { city_names } from "../../../ef-constants";
// Firebase imports
import { Facebook, Google } from "@mui/icons-material"; // Social media icons
import { createUserWithEmailAndPassword } from 'firebase/auth';
import EFMajorCityLookup from "../../../components/EFMajorCityLookup";
import { auth, firestore } from '../../../firebase'; // Import Auth Providers
import UploadPhoto from "../../../functions/UploadPhoto"; // Assuming this is a custom component

// Images
import { default as bgImage } from "../../../assets/images/bg1.jpg";
import exploreVendorsBg from "../../../assets/images/bg2.jpg";
import createEventBg from "../../../assets/images/bgwater2.jpg"; // Background for the step 3 options

// Random YouTube video links
const randomYouTubeVideos = [
  "https://www.youtube.com/embed/dQw4w9WgXcQ",
  "https://www.youtube.com/embed/oHg5SJYRHA0",
  "https://www.youtube.com/embed/tgbNymZ7vqY",
  "https://www.youtube.com/embed/lrpo6dmB2t4",
];

function RegisterUser() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [city, setCity] = useState('');
  
  const [step, setStep] = useState(1); // Step state to control the flow
  const [selectedVideo, setSelectedVideo] = useState(''); // State to store selected video URL
  const [skipPhoto, setSkipPhoto] = useState(false); // Flag for skipping the photo upload step
  const [photoUploaded, setPhotoUploaded] = useState(false); // Flag to track photo upload completion

  // Detect screen size for responsiveness
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate(); // React Router navigate function

  useEffect(() => {
    // Select a random video once when the component mounts
    const randomVideo = randomYouTubeVideos[Math.floor(Math.random() * randomYouTubeVideos.length)];
    setSelectedVideo(randomVideo);
  }, []); // Empty dependency array to ensure this only runs once

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid; // Store the userId after successful sign up
      await createUserData(userCredential.user, username); // Create user data in Firestore

      // Move to Step 2 (Upload Photo)
      setStep(2);
    } catch (err) {
      console.error('Error signing up:', err);
    }
  };

  const createUserData = async (user, username) => {
    try {
      await setDoc(doc(firestore, 'users', user.uid), {
        email: user.email,
        id: user.uid,
        username: username,
        city: city,
        createdAt: new Date(),
      });

    //  console.log('User data created!');
    } catch (err) {
      console.error('Error creating user data:', err);
    }
  };

  const handleSkipPhoto = () => {
    setSkipPhoto(true);
    setStep(3); // Move to Step 3 (Explore Vendors or Create Event)
  };

  const handleNext = () => {
    if (photoUploaded) {
      setStep(3); // Proceed to Step 3 if photo is uploaded
    } else {
      setSkipPhoto(true);
      setStep(3); // Move to Step 3 if photo upload is skipped
    }
  };

  const handleExploreVendors = () => {
    if (city) {
      navigate(`/vendors/`); // Redirect to city-specific vendor page
    } else {
      navigate("/welcome"); // If no city is selected, redirect to welcome page
    }
  };

  const handleCreateEvent = () => {
    navigate("/createnewevent"); // Redirect to the Create Event page
  };

  const handleGoogleSignIn = async () => {
    /*
    try {
      const result = await auth.signInWithPopup(googleAuthProvider);
      console.log("Google Sign-In Success:", result);
    } catch (err) {
      console.error("Google Sign-In Error:", err);
    }
    */
  };

  const handleFacebookSignIn = async () => {
    /*
    try {
      const result = await auth.signInWithPopup(facebookAuthProvider);
      console.log("Facebook Sign-In Success:", result);
    } catch (err) {
      console.error("Facebook Sign-In Error:", err);
    }
    */
  };

  return (
    <>
      <MKBox
        position="fixed"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      />
      
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          
          {/* Left Column - YouTube Video (Visible only on large screens) */}
          {!isSmallScreen && (
            <Grid item xs={12} md={6}>
              <MKBox
                sx={{
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <iframe
                  width="80%"
                  height="100%"
                  src={selectedVideo} // Use the state to show the selected video
                  title="Random YouTube Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </MKBox>
            </Grid>
          )}

          {/* Right Column - Form */}
          <Grid item xs={12} md={5}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {step === 1 ? 'Join Eventflow.app!' : step === 2 ? 'Upload Your Photo' : 'Choose an Option'}
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {step === 1 ? (
                  <MKBox component="form" role="form">
                    <MKBox mb={2}>
                      <MKInput type="text" label="Username&#x2a;" fullWidth onChange={(e) => setUsername(e.target.value)} />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput type="email" label="Email&#x2a;" fullWidth onChange={(e) => setEmail(e.target.value)} />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput type="password" label="Password&#x2a;" fullWidth onChange={(e) => setPassword(e.target.value)} />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput type="password" label="Confirm Password&#x2a;" fullWidth onChange={(e) => setConfirmPassword(e.target.value)} />
                    </MKBox>
                    <MKBox mb={2}>
                      <EFMajorCityLookup
                        options={city_names}
                        label="Nearest Major City"
                        onChange={(newValue) => setCity(newValue)} // Update city state
                      />
                    </MKBox>

                    <MKBox mt={4} mb={1}>
                      <MKButton variant="gradient" color="info" fullWidth onClick={handleSignUp}>
                        Sign Up
                      </MKButton>
                    </MKBox>

                    {/* Social Media Sign-In Options */}
                    <MKBox display="flex" justifyContent="center" mt={2}>
                      <IconButton onClick={handleGoogleSignIn} color="primary">
                        <Google />
                      </IconButton>
                      <IconButton onClick={handleFacebookSignIn} color="primary">
                        <Facebook />
                      </IconButton>
                    </MKBox>
                  </MKBox>
                ) : step === 2 ? (
                  <>
                    <UploadPhoto
                      id={auth.currentUser.uid}  // userId is set after signup
                      source="users"  // Indicates it's a user photo
                      onUploadComplete={(url) => {
                        setPhotoUploaded(true); // Set flag when photo upload is complete
                        console.log('User photo uploaded:', url);
                      }}
                    />
                    <MKBox mt={4} mb={1}>
                      <MKButton variant="outlined" color="info" fullWidth onClick={handleSkipPhoto}>
                        Skip Photo Upload
                      </MKButton>
                    </MKBox>
                    {photoUploaded && (
                      <MKBox mt={2}>
                        <MKButton variant="gradient" color="info" fullWidth onClick={handleNext}>
                          Next
                        </MKButton>
                      </MKBox>
                    )}
                  </>
                ) : (
                  <MKBox>
                    {/* Step 3: Explore Vendors or Create Event */}
                    <MKBox sx={{ backgroundImage: `url(${exploreVendorsBg})`, height: "300px", backgroundSize: "cover" }}>
                      <MKButton onClick={handleExploreVendors} fullWidth>
                        Explore Vendors
                      </MKButton>
                    </MKBox>
                    <MKBox sx={{ backgroundImage: `url(${createEventBg})`, height: "300px", backgroundSize: "cover" }}>
                      <MKButton onClick={handleCreateEvent} fullWidth>
                        Create an Event
                      </MKButton>
                    </MKBox>
                  </MKBox>
                )}
              </MKBox>
            </Card>
          </Grid>

        </Grid>
      </MKBox>
    </>
  );
}

export default RegisterUser;
