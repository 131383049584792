import { useState } from 'react';

// MUI Components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import DefaultFooter from '../../../examples/Footers/DefaultFooter';
import EFVendorInquiryButton from '../../Vendors/Inquiry';

// Eventflow.app React components
import MKBox from "../../../components/MKBox";
import MKTypography from '../../../components/MKTypography';

// About Us page sections
import Information from "../AboutUs/sections/Information.js";
import Team from "../AboutUs/sections/Team.js";

// Routes
import footerRoutes from "../../../footer.routes.js";

// Images
import bgImage from "../../../assets/images/2025eventflowbg.png";


// VendorInquiry Component
import VendorInquiry from "../../Presentation/components/VendorInquiry/index";

function AboutUs() {
  const [venues, setEntries] = useState([]);
  const [city, setCity] = useState("");

  const handleCityChange = (event, value) => {
    setCity(value);
  };

  return (
    <>
      {/* Hero Section */}
      <MKBox
        minHeight="60vh"
        width="100%"
        mt={10}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Become A Vendor
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Discover Eventflow, your ultimate platform for orchestrating unforgettable events. <br /> Available everywhere.
            </MKTypography>

            <EFVendorInquiryButton/>

   
          </Grid>
        </Container>
      </MKBox>

      {/* Main Content Section */}
      <Card
        sx={{
          p: 2,
          mx: { xs: 1, sm: 3 },
          mt: { xs: 1, lg: -8 },
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        {/* Information and VendorInquiry Centering */}
        <Information />

        <Grid container justifyContent="center" sx={{ textAlign: "center", marginBottom: 3 }}>
          <Grid item xs={12} sm={10} md={8} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <VendorInquiry />
          </Grid>
        </Grid>

        {/* Highlighted Cards */}
        <Grid container spacing={2} justifyContent="center" sx={{ textAlign: 'center' }}>
          <Grid item xs={12} sm={6} md={3} >
            <MKBox mb={3}>
              <DefaultInfoCard
                icon="public"
                title="Increased Visibility"
                description="Boost your presence and get noticed by a wide audience of event planners and individuals actively looking for professional services."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MKBox mb={3}>
              <DefaultInfoCard
                icon="payments"
                title="Targeted Audience"
                description="Reach a dedicated audience actively seeking the best event vendors."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MKBox mb={3}>
              <DefaultInfoCard
                icon="apps"
                title="Comprehensive Profiles"
                description="Build a complete profile that reflects your brand and expertise."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MKBox mb={3}>
              <DefaultInfoCard
                icon="3p"
                title="Marketing Support"
                description="Leverage our broad marketing reach to increase your business's exposure."
              />
            </MKBox>
          </Grid>
        </Grid>

        <Team />
      </Card>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
