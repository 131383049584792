import { Box, Button, Card, Grid, TextField } from '@mui/material';
import { collection, doc, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to get vendorId from URL
import MKBox from '../../../../components/MKBox';
import MKTypography from '../../../../components/MKTypography';
import { auth, firestore } from '../../../../firebase'; // Your Firebase setup
import UploadPhoto from '../../../../functions/UploadPhoto';

function AddInventoryPage() {
  const { vendorId } = useParams(); // Get vendorId from URL params
  const [items, setItems] = useState([{ name: '', price: '', description: '', imageUrl: '' }]); // Start with one empty item
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Add each item to Firestore
      for (const item of items) {
        const itemRef = doc(collection(firestore, 'vendors', vendorId, 'Inventory'));
        await setDoc(itemRef, {
            id:itemRef.id,
          name: item.name,
          price: parseFloat(item.price),
          description: item.description,
          imageUrl: item.imageUrl,  // Ensure the image URL is being uploaded along with the other details
        });
      }

      setLoading(false);
      navigate(`/vendor/${auth.currentUser.uid}/inventory`);
    } catch (error) {
      console.error("Error adding items:", error);
      setLoading(false);
    }
  };

  // Handle the upload complete callback and update imageUrl for the item being edited
  const handleUploadComplete = (url, index) => {
    const updatedItems = [...items];
    updatedItems[index].imageUrl = url;  // Set the image URL for the specific item in the array
    setItems(updatedItems);
   // console.log('Upload complete, URL:', url);
  };

  // Handle adding a new item field
  const handleAddItem = () => {
    setItems([...items, { name: '', price: '', description: '', imageUrl: '' }]);
  };

  // Handle input changes for a specific item
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItems = [...items];
    updatedItems[index][name] = value;
    setItems(updatedItems);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#F2F2F2', // EF White
        borderRadius: 2, // Rounded corners
        boxShadow: 3, // Raised shadow
        p: 3, // Padding
        mx: 'auto', // Center horizontally
        width: { xs: '95%', sm: '80%', md: '70%' }, // Responsive width
        mt: 10, // Start at the very top (adjust if there's a header)
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <MKBox px={2} py={4}>
            <MKTypography variant="h4">Add New Item(s)</MKTypography>
            <Card sx={{ p: 3 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {items.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12}>
                        <TextField
                          label="Item Name"
                          fullWidth
                          required
                          name="name"
                          value={item.name}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label="Price"
                          fullWidth
                          type="number"
                          required
                          name="price"
                          value={item.price}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Description"
                          fullWidth
                          multiline
                          rows={4}
                          required
                          name="description"
                          value={item.description}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Image URL"
                          fullWidth
                          value={item.imageUrl} // Show the image URL after upload
                          onChange={(e) => handleInputChange(e, index)}
                          name="imageUrl"
                        />
                      </Grid>

                      {/* UploadPhoto Component */}
                      <UploadPhoto
                        vendorId={auth.currentUser?.uid}  // Assuming the user is logged in
                        source="Inventory"  // This indicates it's an inventory image
                        onUploadComplete={(url) => handleUploadComplete(url, index)}  // Pass index to update correct item
                      />
                    </React.Fragment>
                  ))}

                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleAddItem} // Add another item form
                      fullWidth
                    >
                      Add Another Item
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      disabled={loading}
                      sx={{
                        color: "black",
                        backgroundColor: "#fff"
                      }}
                    >
                      {loading ? "Saving..." : "Add Items"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </MKBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddInventoryPage;
