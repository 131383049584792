import { Box, Button, CircularProgress, Grid, Snackbar, SnackbarContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { firestore } from '../../../../../firebase';

// Define a TypeScript interface for the booking data
interface Booking {
  id: string;
  status: string;
  bookingDate: string; // You can use Date or string depending on how you store the date
  requestor_email: string;
  bookingTime: string;
  booking: string;
  specialRequests: string;
  requestor_name: string;
  requestor_phone: string;
}

interface VendorBookingManagementProps {
  vendorId: string;
  vendorEmail: string;
  onBookingAccepted: (selectedDate: string, selectedTime: string) => void;
}

const VendorBookingManagement = ({
  vendorId,
  vendorEmail,
  onBookingAccepted
}: VendorBookingManagementProps) => {
  const [bookings, setBookings] = useState<Booking[]>([]); // List of all bookings (requested, accepted, rejected)
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const [loading, setLoading] = useState(true); // Loading state
  const [bookingStatus, setBookingStatus] = useState(''); // Booking success/error message

  // Handle Snackbar close event
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Fetch all bookings (requested, accepted, rejected) from Firestore
  const fetchBookings = async () => {
    try {
      const bookingsRef = collection(firestore, 'vendors', vendorId, 'Bookings');
      const q = query(bookingsRef, where('status', 'in', ['requested', 'accepted', 'rejected'])); // Fetch requested, accepted, and rejected bookings
      const querySnapshot = await getDocs(q);

      const bookingsList: Booking[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Booking[];

      setBookings(bookingsList);
    } catch (error) {
      setErrorMessage('Error fetching bookings.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle accepting a booking request
  const handleAcceptBooking = async (bookingId: string, selectedDate: string, selectedTime: string) => {
    try {
      const bookingRef = doc(firestore, 'vendors', vendorId, 'Bookings', bookingId);
      await updateDoc(bookingRef, {
        status: 'accepted', // Update status to accepted
      });

      setBookingStatus('Booking successfully accepted.');
      setOpenSnackbar(true);

      // Call the `onBookingAccepted` function passed via props to notify `BookingForm` about the update
      onBookingAccepted(selectedDate, selectedTime); // Pass selected date and time to `onBookingAccepted`

      // Re-fetch the updated list of all bookings
      fetchBookings();
    } catch (error) {
      console.error('Error accepting booking:', error);
      setErrorMessage('There was an error accepting the booking.');
      setOpenSnackbar(true);
    }
  };

 // const formattedDate = dayjs(booking.bookingDate).format('DD MM YYYY');

  // Handle rejecting a booking request
  const handleRejectBooking = async (bookingId: string) => {
    try {
      const bookingRef = doc(firestore, 'vendors', vendorId, 'Bookings', bookingId);
      await updateDoc(bookingRef, {
        status: 'rejected', // Update status to rejected
      });

      setBookingStatus('Booking successfully rejected.');
      setOpenSnackbar(true);

      // Re-fetch the updated list of all bookings
      fetchBookings();
    } catch (error) {
      console.error('Error rejecting booking:', error);
      setErrorMessage('There was an error rejecting the booking.');
      setOpenSnackbar(true);
    }
  };

  // Fetch bookings when component is mounted
  useEffect(() => {
    fetchBookings();
  }, [vendorId]);

  return (
    <Box sx={{ padding: 3, borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" gutterBottom>
        Manage All Bookings
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Display list of all bookings (requested, accepted, rejected) */}
          {bookings.length > 0 ? (
            <Grid container spacing={2} direction="column">
              {bookings.map((booking) => (
                <Grid item key={booking.id}>
                  <Box sx={{ padding: 2, border: '1px solid gray', borderRadius: 2 }}>
                    <Typography variant="body1">
                      {`Booking ${booking.status} for ${booking.bookingTime} on ${dayjs(booking.bookingDate).format('DD-MM-YYYY')}.`}
                       <br></br>
                       {`Name: ${booking.requestor_name}`}
<br></br>                       {`Number: ${booking.requestor_phone || 'None'}`}
<br></br>
                       {`Special requests: ${booking.specialRequests || 'None'}`}
                    </Typography>
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                      {booking.status === 'requested' && (
                        <>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => handleAcceptBooking(booking.id, booking.bookingDate, booking.bookingTime)}
                            >
                              Accept
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                            sx={{
                                color: "#fff",
                                backgroundColor: "red",
                            }}
                              variant="contained"
                           
                              onClick={() => handleRejectBooking(booking.id)}
                            >
                              Reject
                            </Button>
                          </Grid>
                        </>
                      )}
                      {booking.status === 'accepted' && (
                        <Grid item>
                          <Typography variant="body2" color="green">
                            Accepted
                          </Typography>
                        </Grid>
                      )}
                      {booking.status === 'rejected' && (
                        <Grid item>
                          <Typography variant="body2" color="red">
                            Rejected
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body1" color="textSecondary">No bookings yet.</Typography>
          )}
        </>
      )}

      {/* Snackbar for showing status messages */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <SnackbarContent
          message={errorMessage || bookingStatus}
          sx={{
            backgroundColor: errorMessage ? 'red' : 'green',
            color: 'white',
            fontWeight: 'bold',
            padding: '8px 16px',
          }}
        />
      </Snackbar>
    </Box>
  );
};

// PropTypes validation for props
VendorBookingManagement.propTypes = {
  vendorId: PropTypes.string.isRequired,
  vendorEmail: PropTypes.string.isRequired,
  onBookingAccepted: PropTypes.func.isRequired,
};

export default VendorBookingManagement;
