import { doc, updateDoc } from '@firebase/firestore';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { firestore } from '../../../../../firebase';
import UploadPhoto from '../../../../../functions/UploadPhoto';

const EditEvent = ({ eventId, eventData, onSave, onCancel }) => {
  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  
  // Editable fields state
  const [editableEventName, setEditableEventName] = useState(eventData.event_name || '');
  const [editableEventAddress, setEditableEventAddress] = useState(eventData.event_address || '');
  const [editableEventDescription, setEditableEventDescription] = useState(eventData.event_description || '');
  const [editableEventImage, setEditableEventImage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editableEventDate, setEditableEventDate] = useState(eventData.event_date || '');
  const [editableEventCheckin, setEditableEventCheckin] = useState(eventData.event_checkin || '');
  const [editableEventBudget, setEditableEventBudget] = useState(eventData.event_budget || '');
  const [editableEventCapacity, setEditableEventCapacity] = useState(eventData.event_capacity || '');
  const [editableEventCity, setEditableEventCity] = useState(eventData.event_city || '');
  const [editableEventPublic, setEditableEventPublic] = useState(eventData.isPublic || false);

  // Effect to open dialog when event data is available
  useEffect(() => {
    if (eventData) {
      setEditableEventName(eventData.event_name);
      setEditableEventAddress(eventData.event_address);
      setEditableEventDescription(eventData.event_description);
      setEditableEventDate(eventData.event_date);
      setEditableEventBudget(eventData.event_budget);
      setEditableEventCheckin(eventData.event_checkin);
      setEditableEventCapacity(eventData.event_capacity);
      setEditableEventCity(eventData.event_city);
      setEditableEventPublic(eventData.isPublic);
    }
  }, [eventData]); // Dependency array ensures this runs only when eventData changes



  const handlePhotoUpload = async (photoUrl, eventId) => {
    try {
      const eventsRef = doc(firestore, 'Events', eventId);
     // const eventsRef = collection(firestore, "events");

      await updateDoc(eventsRef, {
        event_image: photoUrl,
      });

      setEditableEventImage(photoUrl);
      setSuccessMessage('Event updated with photo!');

    } catch (error) {
      setErrorMessage('Error occurred while uploading the photo.', error);
    }
  };

  const handleSaveEventEdit = async () => {
    try {
      const updatedEvent = {
        event_name: editableEventName || '',
        event_address: editableEventAddress || '',
        event_description: editableEventDescription || '',
        event_date: editableEventDate || '',
        event_budget: editableEventBudget || '',
        event_capacity: editableEventCapacity || '',
        event_city: editableEventCity || '',
        isPublic: editableEventPublic || '',
        event_checkin: editableEventCheckin || '',
      };
  
      // Firestore update logic
      const docRef = doc(firestore, 'Events', eventId); // Get the document reference
      
      // Perform the update
      await updateDoc(docRef, updatedEvent);
  
      console.log("Event updated successfully!");
      
      // Close the dialog after updating
      setOpenEditEventDialog(false);
    } catch (error) {
      console.error("Error updating event:", error);
      // Optionally display a user-friendly message here (e.g. Snackbars or Toasts)
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        className="ef-sm-btn"
        onClick={() => {setOpenEditEventDialog(true); console.log('Button clicked, opening modal...');}}
        sx={{ marginTop: 2 }}
      >
        Edit Event
      </Button>

      {/* Edit Modal */}
      <Dialog open={openEditEventDialog} onClose={() => setOpenEditEventDialog(false)}>
        <DialogTitle>Edit Event Details</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Event Name"
            value={editableEventName}
            onChange={(e) => setEditableEventName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Event Address"
            value={editableEventAddress}
            onChange={(e) => setEditableEventAddress(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Event Description"
            multiline
            rows={4}
            value={editableEventDescription}
            onChange={(e) => setEditableEventDescription(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Event Date"
            type="date"
            value={editableEventDate}
            onChange={(e) => setEditableEventDate(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            label="Event Budget"
            type="number"
            value={editableEventBudget}
            onChange={(e) => setEditableEventBudget(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Event Capacity"
            type="number"
            value={editableEventCapacity}
            onChange={(e) => setEditableEventCapacity(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Event City"
            value={editableEventCity}
            onChange={(e) => setEditableEventCity(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <UploadPhoto
            id={eventId}
            source="events"
            onUploadComplete={(photoUrl) => handlePhotoUpload(photoUrl, eventId)}
          />

<Box>
            <Typography variant="body2">Create A Check In:</Typography>
            <input
              type="checkbox"
              checked={editableEventCheckin}
              onChange={() => setEditableEventCheckin(!editableEventCheckin)}
            />
          </Box>

          <Box>
            <Typography variant="body2">Make Event Public:</Typography>
            <input
              type="checkbox"
              checked={editableEventPublic}
              onChange={() => setEditableEventPublic(!editableEventPublic)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditEventDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveEventEdit} color="primary">
            Save
          </Button>
        </DialogActions>
        <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
      <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />
      </Dialog>
    </>
  );
};

EditEvent.propTypes = {
    eventId: PropTypes.string.isRequired,
    eventData: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

export default EditEvent;
