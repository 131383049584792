import { Button, Card, Grid } from "@mui/material";
import { useState } from "react";
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";

// Firebase imports
import {
  browserLocalPersistence,
  browserSessionPersistence,
  FacebookAuthProvider,
  GoogleAuthProvider,
  setPersistence,
  signInWithPopup
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, handleSignIn } from "../../../firebase";


function UserSignIn() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);


  /*
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      Cookies.set('user', JSON.stringify(auth.currentUser)); // Store user in cookie

      setError(null);
      alert("Signed in successfully!");
      navigate("/");
    } catch (err) {
      console.error("Error signing in:", err);
      setError(err.message);
    }
  };
  */

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignIn(email, password, rememberMe);
  };

  const handleSignInWithProvider = async (provider) => {
    try {
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
      await signInWithPopup(auth, provider);
      setError(null);
      alert("Signed in successfully with provider!");
      navigate("/");
    } catch (err) {
      console.error("Error signing in with provider:", err);
      setError(err.message);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "password") {
      setPassword(value);
    }
    if (id === "email") {
      setEmail(value);
    }
  };

  
  return (
    <>
      <MKBox
        position="fixed"
        top={60}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: 'linear-gradient(to right, #1e6c97, #0f4c6b)',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      />

      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3} mt={15} mb={10}>
            <Card sx={{ padding: 3 }}>
              <MKBox pt={4} pb={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      id="email"
                      label="Email"
                      required
                      onChange={(e) => handleInputChange(e)}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      id="password"
                      label="Password"
                      required
                      onChange={(e) => handleInputChange(e)}
                      fullWidth
                    />
                  </MKBox>

                  <MKBox mt={4} mb={2}>
                    {/* Sign in button with gradient blue background */}
                    <Button
                      className="signIn"
                      fullWidth
                      onClick={handleSubmit}
                      sx={{
                        background: 'linear-gradient(to right, #1e6c97, #0f4c6b)',
                        color: 'white',
                        fontWeight: 'bold',
                        '&:hover': {
                          background: 'linear-gradient(to right, #1a4b6d, #0d3b52)',
                        },
                      }}
                    >
                      Sign in
                    </Button>

                    {/* Sign in with providers */}
                    <Button  variant="gradient" color="info" fullWidth onClick={() => handleSignInWithProvider(new FacebookAuthProvider())}>
                      sign in with facebook
                    </Button>
                    <Button  variant="gradient" color="info" fullWidth onClick={() => handleSignInWithProvider(new GoogleAuthProvider())}>
                      sign in with google
                    </Button>
                  </MKBox>

                  {error && <p style={{ color: "red" }}>{error}</p>}

                  {/* Sign up and remember me checkbox */}
                  <MKBox mt={2} textAlign="center">
                    <a href="/welcometoeventflow">Sign up!</a>
                  </MKBox>

                  <MKBox mt={2} display="flex" justifyContent="center" alignItems="center">
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleSetRememberMe}
                        style={{ marginRight: 5 }}
                      />
                      Remember Me
                    </label>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default UserSignIn;
