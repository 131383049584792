import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, Modal, Typography } from "@mui/material";
import { collection, getDocs } from 'firebase/firestore';
import { FC, useEffect, useState } from 'react';
import { firestore } from '../../../../firebase';

interface ProfileProps {
  id: string;
  style?: React.CSSProperties; // Allow style prop
  tier: string; // Accept tier as a string
}

interface ImageData {
  pictureURL: string;
  picture_caption?: string; // Optional caption property
}

const InventoryPreview: FC<ProfileProps> = ({ id, style, tier }) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fetch images from Firestore
  async function fetchImages() {
    try {
      const imagesCollectionRef = collection(firestore, 'vendors', id, 'Images'); // Fetching images from the correct path
      const querySnapshot = await getDocs(imagesCollectionRef);
      const fetchedImages: ImageData[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.pictureURL) {
          fetchedImages.push({
            pictureURL: data.pictureURL, // Make sure this is the correct field name
            picture_caption: data.picture_caption,
          });
        }
      });

      if (parseInt(tier) < 2) {
        setImages(fetchedImages.slice(0, 5)); // Show only the first 5 images
      } else {
        setImages(fetchedImages); // Show all images
      }
    } catch (error) {
      console.error('Error fetching images: ', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchImages();
  }, [id, tier]); // Add tier as dependency to update images when tier changes

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setSelectedImage(images[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleShowMore = () => {
    setShowMoreOpen(true);
  };

  const handleCloseShowMore = () => {
    setShowMoreOpen(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setSelectedImage(images[currentImageIndex + 1]);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setSelectedImage(images[currentImageIndex - 1]);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={style}>
      <Grid container padding={2} justifyContent="center" alignItems="center" height="100%">

        <div>
          {/* Display the main image as a banner with slideable navigation */}
          {images.length > 0 && (
            <Box position="relative" width="100%" height="auto">
              <img
                src={images[currentImageIndex].pictureURL}
                alt={images[currentImageIndex].picture_caption}
                loading="lazy"
                style={{
                  display: 'block',
                  width: '77vw', // Full width of the screen (100% of viewport width)
                  height: 'auto', // Height adjusts based on aspect ratio
                  maxHeight: '30vh', // Max height should be 50% of the viewport height
                  objectFit: 'cover', // Ensures the image is contained within the constraints without cropping
                  borderRadius: '8px', // Optional: rounded corners for the images
                }} 
              />
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '10px',
                transform: 'translateY(-50%)',
                zIndex: 2,
              }}>
                {/* Left Arrow */}
                <IconButton onClick={handlePrevImage} disabled={currentImageIndex === 0} style={{ color: 'white' }}>
                  <span>❮</span>
                </IconButton>
              </div>
              <div style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                zIndex: 2,
              }}>
                {/* Right Arrow */}
                <IconButton onClick={handleNextImage} disabled={currentImageIndex === images.length - 1} style={{ color: 'white' }}>
                  <span>❯</span>
                </IconButton>
              </div>
            </Box>
          )}

          {images.length > 1 && (
            <Button 
              style={{ position: 'relative', marginTop: '5px', float: 'right', color:'#1e6c97' }}
              onClick={handleShowMore}
            > Gallery
              {/* <PhotoSizeSelectActual /> */}
            </Button>
          )}
        </div>
      </Grid>

      {/* Modal for displaying larger image */}
      <Modal open={open} onClose={handleClose}>
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',  // Reduce height for the modal
            width: '80%',   // Restrict the modal width
            bgcolor: 'background.paper',
            position: 'relative',
            p: 2,
            maxHeight: '90vh',  // Limit height
            maxWidth: '90vw',   // Limit width
            overflow: 'hidden',  // Prevent overflow
            margin: 'auto',
            boxShadow: '0 2px 8px rgba(0,0,0,0.25)', // Add a box shadow for better visual effect
          }}
        >
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Close />
          </IconButton>
          <IconButton onClick={handlePrevImage} sx={{ position: 'absolute', left: 16 }}>
            <span>❮</span>
          </IconButton>
          <IconButton onClick={handleNextImage} sx={{ position: 'absolute', right: 16 }}>
            <span>❯</span>
          </IconButton>
          {selectedImage && (
            <>
              <img
                src={selectedImage.pictureURL}
                alt="Selected Vendor"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain', // Ensure the full image is visible
                  cursor: 'zoom-in' // Optional: zoom-in cursor
                }} 
              />
              {selectedImage.picture_caption && (
                <Typography
                  variant="h6"
                  sx={{
                    position: 'absolute',
                    bottom: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    padding: '0.5em',
                    textAlign: 'center',
                  }}
                >
                  {selectedImage.picture_caption}
                </Typography>
              )}
              {/* Pinterest Pin It Button */}
              <a
                href={`https://www.pinterest.com/pin/create/button/?url=${window.location.href}&media=${selectedImage.pictureURL}&description=${selectedImage.picture_caption}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  position: 'absolute',
                  bottom: 20,
                  right: 20,
                  backgroundColor: '#e60023',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Pin It
              </a>
            </>
          )}
        </Box>
      </Modal>

      {/* Modal for displaying all images */}
      <Modal open={showMoreOpen} onClose={handleCloseShowMore}>
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
            width: '80%',
            bgcolor: 'background.paper',
            position: 'relative',
            p: 2,
            overflowY: 'auto', // Allow scrolling if there are many images
            maxHeight: '90vh', // Optional to limit height and allow scrolling
            boxShadow: '0 2px 8px rgba(0,0,0,0.25)',
            margin: 'auto',
          }}
        >
          <IconButton onClick={handleCloseShowMore} sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Close />
          </IconButton>
          <Grid container spacing={2}>
            {images.map((imageData, index) => (
              <Grid item xs={4} key={index}>
                <div style={{
                  position: 'relative',
                  width: '100%',
                  height: 'auto', // Allow height to be dynamic based on image aspect ratio
                  overflow: 'hidden', // Hide overflow (if any)
                }}>
                  <img
                    src={imageData.pictureURL}
                    alt={imageData.picture_caption}
                    loading="lazy"
                    style={{
                      objectFit: 'contain', // Ensures the image fits inside the container without cropping
                      width: '100%', // Make sure the image width is 100% of the container's width
                      height: 'auto', // Height is auto to maintain aspect ratio
                      borderRadius: '8px',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                      cursor: 'pointer', // Pointer cursor for clickable images
                    }}
                    onClick={() => handleImageClick(index)} // Click to view larger image
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default InventoryPreview;
