import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EFCreateEvent: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateEvent = () => {
    navigate('/createnewevent');
  };

  return (
    <Button
      className="ef-title"
      onClick={handleCreateEvent}
      sx={{
        borderRadius: "1rem", // Rounded corners for the button
        textAlign: "center",
        padding: "12px 20px", // Padding adjusted for a larger button
        margin: "10px auto",
        width: { xs: "80%", sm: "60%", md: "50%" }, // Responsive width based on screen size
        height: "60px", // Fixed height for the button
        display: "block",
        color: "#fff",
        backgroundColor: "#ff8a41",
        fontSize: { xs: "1rem", sm: "1.1em", md: "1.2em" }, // Adjust font size for responsiveness
        fontWeight: "bold",
        textDecoration: "none", // Remove underline
        transition: "all 0.3s ease-in-out", // Smooth transition for hover effects
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Light shadow for depth
        "&:hover": {
          transform: "scale(1.1)", // Make button bigger on hover
        },
        "&:active": {
          transform: "scale(1)", // Reset button size on click
        },
      }}
    >
      Start Planning
    </Button>
  );
};

export default EFCreateEvent;
