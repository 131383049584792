import { Search } from '@mui/icons-material';
import { Container, Grid, InputAdornment, TextField } from '@mui/material';
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import EFCard from '../../components/EFCard';
import { firestore } from '../../firebase';

const ViewVendors: React.FC = () => {
  const { city } = useParams<Params>(); // Extract the city parameter from the URL
  const [vendors, setVendors] = useState<any[]>([]);
  const [venues, setVenues] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredVendors, setFilteredVendors] = useState<any[]>([]);

  async function getVendors() {
    const q = query(collection(firestore, "vendors"), where("vendor_majorCity", "==", decodeURIComponent(city!)), limit(100)); // Increase limit for better filtering
    const querySnapshot = await getDocs(q);
    
    const entries = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setVendors(entries);
  }


  async function getVenues() {
    const q = query(collection(firestore, "vendors"), where("vendor_majorCity", "==", decodeURIComponent(city!)), where("vendor_type", "==", "Venue"), limit(100)); // Increase limit for better filtering
    const querySnapshot = await getDocs(q);
    
    const entries = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setVenues(entries);
  }


  useEffect(() => {
    getVendors();
    getVenues();
  }, [city]);


  useEffect(() => {
    // Filter vendors based on search query
    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered = vendors.filter(vendor =>
        Object.values(vendor).some(value =>
          String(value).toLowerCase().includes(lowerCaseQuery)
        )
      );
      setFilteredVendors(filtered);
    } else {
      setFilteredVendors(vendors); // Reset to all vendors when search query is cleared
    }
  }, [searchQuery, vendors]);

  return (
    <div>
      <Container>
        {vendors.length === 0 ? (
          <p style={{ textAlign: 'center', marginTop: '15px' }}>
            No vendors have been added for this major city yet.
            <br />
            Ready to showcase your services? 
            <br />
            <a href="#">Join Eventflow</a> and add your business today to reach more customers in your area.
          </p>
        ) : (
          <Grid container spacing={3} direction="column">
            {/* Search bar on top */}
            <Grid item xs={12}>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search for vendors..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    borderRadius: '50px',
                    padding: '10px 20px',
                    backgroundColor: '#f7f7f7',
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    transition: '0.3s ease-in-out',
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '50px',
                    },
                    '&:hover': {
                      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15)',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc', // Border color
                      },
                      '&:hover fieldset': {
                        borderColor: '#007bff', // Hover border color
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#007bff', // Focus border color
                      },
                    },
                  }}
                />
              </div>
            </Grid>

            {/* Vendor cards below */}
            <Grid item xs={12}>
              <h3>All Vendors</h3>
              <EFCard posts={searchQuery ? filteredVendors : vendors} /> {/* Show filtered or all vendors */}
            </Grid>   
            
            <Grid item xs={12}>
              <h3>Venues</h3>
              <EFCard posts={searchQuery ? filteredVendors : venues} /> {/* Show filtered or all vendors */}
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default ViewVendors;
