import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Snackbar,
} from '@mui/material';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import { auth, firestore } from '../../../firebase';
import BaseLayout from '../../../layouts/sections/components/BaseLayout';
import { useCart } from '../../Users/Cart/CartContext';
import EditInventory from './EditInventory'; // Import edit dialog

const InventoryPage = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVendor, setIsVendor] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const { vendorId } = useParams();

  const fetchInventoryAndCheckOwnership = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        navigate('/');
        return;
      }

      const vendorDocRef = doc(firestore, 'vendors', vendorId);
      const vendorDoc = await getDoc(vendorDocRef);
      const vendorData = vendorDoc.data();

      if (currentUser.uid === vendorData.vendor_own) {
        setIsVendor(true);
      }

      const inventoryCollectionRef = collection(firestore, 'vendors', vendorId, 'Inventory');
      const inventorySnapshot = await getDocs(inventoryCollectionRef);
      const inventoryList = inventorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setInventory(inventoryList);
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setErrorMessage('Failed to load inventory.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventoryAndCheckOwnership();
  }, [vendorId, navigate]);

  const handleAddToCart = (item) => {
    if (!item || !item.id || !item.name || !item.price) {
      setErrorMessage('Invalid item details.');
      return;
    }

    setSuccessMessage(`Item "${item.name}" added to cart.`);
    addToCart(vendorId, item); // Add item to cart
  };

  return (
    <BaseLayout
      title="Inventory"
      breadcrumb={[
        { label: "Vendor Page", route: `/vendor/${vendorId}` },
        { label: "Inventory", route: `/vendor/${vendorId}/inventory` },
      ]}
    >
      <Grid>
        <MKBox>
          {isVendor && (
            <Button
              variant="contained"
              onClick={() => navigate(`/vendor/${vendorId}/add-inventory`)}
              sx={{ marginBottom: 3, backgroundColor: '#1e6c97', color: '#fff' }}
            >
              Add New Item
            </Button>
          )}

          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={3} justifyContent="center">
              {inventory.length > 0 ? (
                inventory.map((item) => (
                  <Grid item key={item.id} xs={6} sm={6} md={4}>
                    <Card>
                      {item.imageUrl && (
                        <CardMedia
                          component="img"
                          height="auto"
                          image={item.imageUrl}
                          alt={item.name}
                          sx={{ objectFit: 'fit', width: '90%', maxHeight: '250px' }}
                        />
                      )}
                      <CardContent>
                        <MKTypography variant="h6" gutterBottom>
                          {item.name}
                        </MKTypography>
                        <MKTypography
                          variant="body2"
                          sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                          color="textSecondary"
                        >
                          {item.description}
                        </MKTypography>
                        <MKTypography variant="h6" color="primary" mt={2}>
                          ${item.price}
                        </MKTypography>

                        {isVendor ? (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => setDialogOpen(true)}
                            fullWidth
                            sx={{ marginTop: 2, color: '#1e6c97' }}
                          >
                            Edit
                          </Button>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              color="success"
                              onClick={() => handleAddToCart(item)}
                              fullWidth
                              sx={{ marginTop: 2 }}
                            >
                              Add to Cart
                            </Button>
{/*                             <EFAddToAction
  actionType="cart"
  vendorId="12345"
  fetchItems={fetchAvailableItems}
  handleAddToEvent={addVendorToEvent}
/> 
                            <EFAddToEvent vendorId={vendorId} vendorType={item.type} capacity={item.capacity} />
                            */}
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <MKTypography variant="h6" color="textSecondary">
                  No items in inventory
                </MKTypography>
              )}
            </Grid>
          )}

          <Snackbar
            open={!!errorMessage}
            autoHideDuration={8000}
            onClose={() => setErrorMessage('')}
            message={errorMessage}
          />
          <Snackbar
            open={!!successMessage}
            autoHideDuration={8000}
            onClose={() => setSuccessMessage('')}
            message={successMessage}
          />
          <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />
        </MKBox>
      </Grid>

      {dialogOpen && <EditInventory open={dialogOpen} onClose={() => setDialogOpen(false)} />}
    </BaseLayout>
  );
};

export default InventoryPage;
