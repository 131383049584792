import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Snackbar,
  SnackbarContent,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase';

const BookingForm = ({ vendorId, vendorEmail, onBookingAccepted, vendorName }) => {
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookingStatus, setBookingStatus] = useState('');
  const [requestor_name, setRequestorName] = useState('');
  const [requestor_phone, setRequestorPhone] = useState('');
  const [specialRequests, setSpecialRequests] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseSnackbar = () => setOpenSnackbar(false);
  const toggleModal = () => setOpenModal(!openModal);

  const fetchAvailableTimes = async () => {
    try {
      setLoading(true);
      const timesRef = collection(firestore, 'vendors', vendorId, 'AvailableTimes');
      const querySnapshot = await getDocs(timesRef);
      const availableList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAvailableTimes(availableList);
    } catch (error) {
      console.error('Error fetching available times:', error);
      setErrorMessage('Error fetching available times.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTimeSelect = (time) => setSelectedTime(time);

  const handleSubmitBooking = async () => {
    if (!selectedTime || !selectedDate) {
      setErrorMessage('Please select both a date and a time.');
      setOpenSnackbar(true);
      return;
    }

    if (!auth.currentUser) {
      setErrorMessage('You must be logged in to book.');
      setOpenSnackbar(true);
      return;
    }

    try {
      const bookingRef = doc(
        firestore,
        `vendors/${vendorId}/Bookings`,
        `${auth.currentUser.uid}-${selectedDate.toISOString()}-${selectedTime}`
      );
      await setDoc(bookingRef, {
        userId: auth.currentUser.uid,
        requestor_email: auth.currentUser.email,
        vendorId,
        requestor_name,
        requestor_phone,
        specialRequests,
        vendorEmail: vendorEmail.trim(),
        bookingDate: selectedDate.toISOString(),
        bookingTime: selectedTime,
        status: 'requested',
        createdAt: new Date(),
      });

      onBookingAccepted(selectedDate.toISOString(), selectedTime);
      setBookingStatus(`Successfully booked ${selectedTime} on ${selectedDate.format('MMMM D, YYYY')} with ${vendorId}`);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error creating booking:', error);
      setErrorMessage('There was an error while booking. Please try again.');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchAvailableTimes();
  }, [vendorId]);

  return (
    <div>
          <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">

      {/* Button to Open Modal */}
      <Button className='ef-sm-btn' onClick={toggleModal}>
        View Availability
      </Button>
      </Grid>

      {/* Modal with Booking Form */}
      <Modal open={openModal} onClose={toggleModal} aria-labelledby="booking-modal" aria-describedby="booking-form">
        <Box sx={{ width: '90%', margin: 'auto', marginTop: '10%', p: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Typography variant="h5" gutterBottom>
            Book a Time Slot with {vendorName}
          </Typography>
          <Grid container spacing={3}>
            {/* Left Column: Requestor Details */}
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={selectedDate}
                  onChange={(newDate) => {
                    setSelectedDate(newDate);
                    setSelectedTime('');
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  disableToolbar
                  shouldDisableDate={(date) =>
                    !availableTimes.some((time) => dayjs(time.date).isSame(date, 'day'))
                  }
                  inputFormat="MM/DD/YYYY"
                />
              </LocalizationProvider>
              <TextField
                label="Name"
                value={requestor_name}
                onChange={(e) => setRequestorName(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Phone"
                value={requestor_phone}
                onChange={(e) => setRequestorPhone(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Special Requests"
                value={specialRequests}
                onChange={(e) => setSpecialRequests(e.target.value)}
                multiline
                rows={4}
                fullWidth
                sx={{ mt: 2 }}
              />
            </Grid>

            {/* Right Column: Available Times */}
            <Grid item xs={12} sm={6}>
              <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  availableTimes
                    .filter((timeObj) => dayjs(timeObj.date).isSame(selectedDate, 'day'))
                    .map((timeObj) => (
                      <Button
                        key={timeObj.id}
                        variant="outlined"
                        onClick={() => handleTimeSelect(timeObj.time)}
                        sx={{
                          borderColor: selectedTime === timeObj.time ? 'success.main' : 'grey.400',
                          color: selectedTime === timeObj.time ? 'success.main' : 'inherit',
                          mt: 1,
                        }}
                      >
                        {timeObj.time}
                      </Button>
                    ))
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Confirm Booking */}
          <Button variant="contained" color="success" onClick={handleSubmitBooking} fullWidth sx={{ mt: 3 }}>
            Request Booking
          </Button>
        </Box>
      </Modal>

      {/* Snackbar for Messages */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <SnackbarContent
          message={errorMessage || bookingStatus}
          sx={{ backgroundColor: errorMessage ? 'red' : 'green', color: 'white', fontWeight: 'bold' }}
        />
      </Snackbar>
    </div>
  );
};

BookingForm.propTypes = {
  vendorId: PropTypes.string.isRequired,
  vendorEmail: PropTypes.string.isRequired,
  onBookingAccepted: PropTypes.func.isRequired,
  vendorName: PropTypes.string.isRequired,
};

export default BookingForm;
