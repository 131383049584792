import { addDoc, collection, doc } from 'firebase/firestore'; // Firestore methods
import PropTypes from 'prop-types'; // Import PropTypes
import { createContext, useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase'; // Firebase setup

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [eventsCart, setEventsCart] = useState({});
  const user = auth.currentUser;

  // Fetch user's cart for each event
  const fetchCart = async () => {
    if (!user) return;

    try {
      const eventsRef = doc(collection(firestore, "users", auth.currentUser.uid, "events"));
      const eventsSnapshot = await eventsRef.get();

      const events = {};

      for (const eventDoc of eventsSnapshot.docs) {
        const eventId = eventDoc.id;
        const cartRef = eventDoc.ref.collection('cart');
        const cartSnapshot = await cartRef.get();

        // Save cart items for each event in the eventsCart state
        events[eventId] = cartSnapshot.docs.map((doc) => doc.data());
      }

      setEventsCart(events);
    } catch (error) {
      console.error('Error fetching cart:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add item to the cart for a specific event
  const addToCart = async (eventId, item) => {
    if (!user) return;

    console.log("Adding to cart:", item);  // Check if item is correctly structured

    const cartItem = {
      id: item.id,
      name: item.name,
      price: item.price,
      imageUrl: item.imageUrl || '',
      description: item.description || '',
    };

    try {
      const eventDocRef = doc(firestore, 'users', user.uid, 'events', eventId);
      const cartCollectionRef = collection(eventDocRef, 'cart'); // Cart is a sub-collection of each event

      // Add the cart item to Firestore using arrayUnion (add the cart item to the "cart" sub-collection)
      await addDoc(cartCollectionRef, cartItem);  // Use addDoc for adding a document to the collection

      // Update local cart state for this event
      setEventsCart((prev) => ({
        ...prev,
        [eventId]: prev[eventId] ? [...prev[eventId], cartItem] : [cartItem],
      }));
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  // Remove item from cart for a specific event
  const removeFromCart = async (eventId, itemId) => {
    if (!user) return;

    try {
      const eventDocRef = doc(firestore, 'users', user.uid, 'events', eventId);
      const cartCollectionRef = eventDocRef.collection('cart'); // Cart is a sub-collection of each event

      // Find the specific item by its id and delete it from Firestore
      const cartItemRef = cartCollectionRef.where('id', '==', itemId);
      const cartItemSnapshot = await cartItemRef.get();
      cartItemSnapshot.forEach(async (doc) => {
        await doc.ref.delete();
      });

      // Update local cart state for this event
      setEventsCart((prev) => ({
        ...prev,
        [eventId]: prev[eventId]?.filter((item) => item.id !== itemId),
      }));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

// Calculate the total price of all items in the cart
const getTotalPrice = () => {
  let total = 0;

  // Loop through eventsCart and calculate total price
  for (const eventId in eventsCart) {
    // Use Object.prototype.hasOwnProperty to safely check for properties
    if (Object.prototype.hasOwnProperty.call(eventsCart, eventId)) {
      const cartItems = eventsCart[eventId];
      total += cartItems.reduce((sum, item) => sum + item.price * (item.quantity || 1), 0);
    }
  }

  return total;
};


  useEffect(() => {
    fetchCart();
  }, [user]);

  return (
    <CartContext.Provider value={{ eventsCart, addToCart, removeFromCart, getTotalPrice, loading }}>
      {children}
    </CartContext.Provider>
  );
};

// PropTypes for validation
CartProvider.propTypes = {
  children: PropTypes.node.isRequired,  // Validate that `children` is a required prop
};

export default CartProvider;
