import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputLabel, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import PropTypes from "prop-types";
import { useState } from "react";
import MKBox from "../../../../components/MKBox";
import { businessTypeDropdown } from "../../../../ef-constants";
import { firestore } from "../../../../firebase";

const EFVendorInquiryDialog = ({ open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    businessType: [],
    address: "",
    description: "",
    website: "",
  });
  const [vendorType, setVendorType] = useState([]);
  const [featureVendor, setFeatureVendor] = useState(false);
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e.target.value });
  };

  const handleTypeChange = (event) => {
    setVendorType(event.target.value);
    setFormData({
      ...formData,
      businessType: event.target.value,
    });
  };

  const handleFeatureVendorChange = (event) => {
    setFeatureVendor(event.target.checked);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    setError('');
    if (!formData.name || !formData.email || !formData.phone || !formData.businessType.length || !formData.address || !formData.description || !formData.website) {
      setError("All fields are required.");
      return;
    }
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email.");
      return;
    }

    try {
      const vendorCollection = collection(firestore, "vendorInquiries");

      const vendorData = {
        vendor_name: formData.name,
        vendor_email: formData.email,
        vendor_phone: formData.phone,
        vendor_type: formData.businessType,
        vendor_address: formData.address,
        vendor_description: formData.description,
        vendor_website: formData.website,
        vendor_featured: featureVendor,
        vendor_created: Date.now(),
      };

      await addDoc(vendorCollection, vendorData);

      setSuccessMessage("Vendor successfully added! You will hear from us soon :)");
      onClose(); // Close dialog after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        businessType: [],
        address: "",
        description: "",
        website: "",
      });
      setFeatureVendor(false);
    } catch (e) {
      console.error("Error adding document:", e);
      setError("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <>
      <Snackbar open={!!errorMessage} autoHideDuration={8000} onClose={() => setErrorMessage('')} message={errorMessage} />
      <Snackbar open={!!successMessage} autoHideDuration={8000} onClose={() => setSuccessMessage('')} message={successMessage} />

      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Register Your Business</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}

          <TextField margin="normal" label="Business Name" name="name" fullWidth value={formData.name || ""} onChange={handleChange} />
          <TextField margin="normal" label="Email" name="email" type="email" fullWidth value={formData.email || ""} onChange={handleEmailChange} helperText={error && "Please enter a valid email address"} error={error && !validateEmail(formData.email)} />
          <TextField margin="normal" label="Phone" name="phone" fullWidth value={formData.phone || ""} onChange={handleChange} />
          <TextField margin="normal" label="Address" name="address" fullWidth value={formData.address || ""} onChange={handleChange} />

          {/* Business Type (Multiple Selection) */}
          <MKBox mb={2}>
            <InputLabel>Business Type</InputLabel>
            <Select labelId="vendorType" id="vendorType" value={vendorType || []} onChange={handleTypeChange} fullWidth multiple>
              {businessTypeDropdown.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </MKBox>

          <TextField margin="normal" label="Description" name="description" multiline rows={4} fullWidth value={formData.description || ""} onChange={handleChange} />
          <TextField margin="normal" label="Website" name="website" fullWidth value={formData.website || ""} onChange={handleChange} />

          {/* Feature Vendor Checkbox */}
          <FormControlLabel control={<Checkbox checked={featureVendor} onChange={handleFeatureVendorChange} />} label="Would you like to be featured as a vendor?" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EFVendorInquiryDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

export default EFVendorInquiryDialog;
