import { doc, getDoc } from "firebase/firestore";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { firestore } from "../../../../firebase";
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";

const EventDashboard = ({ eventId }) => {
  const [checkIns, setCheckIns] = useState([]);

  useEffect(() => {
    const fetchCheckIns = async () => {
      try {
        const eventDocRef = doc(firestore, "Events", eventId);
        const eventDoc = await getDoc(eventDocRef);

        if (eventDoc.exists()) {
          setCheckIns(eventDoc.data().check_ins || []);
        }
      } catch (error) {
        console.error("Error fetching check-ins:", error);
      }
    };

    fetchCheckIns();
  }, [eventId]);

  return (
    <BaseLayout
      title="Event Dashboard"
      breadcrumb={[
        { label: "Events", route: "/events" },
        { label: "My Event", route: `/events/${eventId}` },
        { label: "Dashboard", route: `/events/${eventId}/dashboard` },
      ]}
    >
      <div>
        <h2>Check-In List</h2>
        {checkIns.length === 0 ? (
          <p>No one has checked in yet.</p>
        ) : (
          <ul>
            {checkIns.map((checkIn, index) => (
              <li key={index}>
                {checkIn.name} ({checkIn.email}) - Checked in at{" "}
                {new Date(checkIn.checkedInAt.seconds * 1000).toLocaleString()}
              </li>
            ))}
          </ul>
        )}
      </div>
    </BaseLayout>
  );
};

EventDashboard.propTypes = {
  eventId: PropTypes.string.isRequired,
};

export default EventDashboard;
