import { Box, Button, Grid, InputLabel, MenuItem, Modal, Select, Snackbar, TextField, Typography } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EFUploadVideo from "../../../../components/EFUploadVideo";
import EFVideoPlayer from '../../../../components/EFVideoPlayer';
import { businessTypeDropdown } from "../../../../ef-constants";
import { auth, firestore } from '../../../../firebase';
import UploadPhoto from "../../../../functions/UploadPhoto";
// Interface for the vendor document (you can still define specific fields if you want)
interface Vendor {
  vendor_description: string;  // Specify this field here if needed
  // Add other fields as necessary
  vendor_own: string;
  // etc.
}

interface ProfileProps {
  id: string;
  avi: string;
  name: string;
  thisRating: number;
  thisType: string;
  thisDescription: string;
  video: string;
  email: string;
  tier: number;
  capacity: number;
  vendor_own: string; // Used for ownership verification
}

const Profile: FC<ProfileProps> = ({
  id,
  avi,
  name,
  thisRating,
  thisType,
  thisDescription,
  video,
  email,
  tier,
  capacity,
  vendor_own
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedAvi, setEditedAvi] = useState(avi);
  const [editedDescription, setEditedDescription] = useState(thisDescription);
  const [editedVideo, setEditedVideo] = useState(video);
  const [editedName, setEditedName] = useState(name);
  const [editedVendorType, setType] = useState(thisType);
  const [editedTier, setTier] = useState(tier);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


/*   const businessTypeDropdown = [
    'Catering', 'Designers and Stylists', 'DJs and Promoters', 'Event Decorator', 'Equipment Rental', 'Experience', 'Event Planner', 'Photography and Videography', 'Security', 'Transportation', 'Venue'
  ]; */

  // Check if the current user is the owner of the vendor
  const canEdit = auth.currentUser?.uid === vendor_own;

  // Open and close modal functions
  const handleEditClick = () => setIsEditing(true);
  const handleClose = () => setIsEditing(false);
  const randID = id + Date.now();
const navigate = useNavigate();

  // Handle save functionality
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleAddToFavorites = async () => {
    try {
      const favoritesDocRef = doc(firestore, 'users', auth.currentUser?.uid, 'Favorites', id);
      await setDoc(favoritesDocRef, {
        vendor_id: id,
        vendor_name: name,
        vendor_type: thisType,
        added_at: new Date(),
      }, { merge: true });

      setSuccessMessage('Vendor added to favorites successfully!');
    } catch (error) {
      setErrorMessage(`Error adding vendor to favorites: ${error.message}`);
    }
  };

  const handleSave = async () => {
    try {
      const vendorDocRef = doc(firestore, 'vendors', id);

      // Use a flexible type for the update object (Record<string, any> or { [key: string]: any })
      const updateData: Record<string, any> = {
        vendor_name: editedName,
        vendor_video: editedVideo ?? '',
        vendor_pictureUrl: editedAvi,
        vendor_description: editedDescription, 
        vendor_type: editedVendorType, 
        vendor_tier: editedTier,// You can dynamically add more fields here if needed
      };

      await setDoc(vendorDocRef, updateData, { merge: true });
            setSuccessMessage("Vendor details updated successfully!"); // Replace with a toast if preferred
      setIsEditing(false);
    } catch (error) {
      setErrorMessage("Error updating vendor details: " + error.message); // Replace with a toast if preferred
    }
  };

  const labels: { [index: number]: string } = {
    0: 'No Rating',
    1: 'Terrible',
    2: 'Poor',
    3: 'Okay',
    4: 'Good',
    5: 'Excellent',
  };

  const defaultVideo = 'https://youtube.com/shorts/pkjRR-v3xFU?si=d_HZiQSVMEHnzViR';
  const videoUrl = video || defaultVideo;

  return (
    <>
      <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">


        <Box>
          <div  style={{ marginBottom: '0.5em' }}>
       
        <div>
    


    </div>
      </div>
          {canEdit && (
            <>
            <Button onClick={handleEditClick} variant="contained" color="info">
              Edit Profile
            </Button>
         
                        </>

          )}
        </Box>


        {/* Vendor Details */}
        <Grid  container>
          <Grid item xs={12} sm={8} pt={2}>
            <h3>Description</h3>
            <hr></hr>
            <Typography sx={{
      whiteSpace: 'pre-wrap', // Preserve line breaks and spaces
      wordWrap: 'break-word', // Ensure long words break correctly in narrow containers
    }}>{thisDescription || "This vendor hasn’t provided additional details yet."}</Typography>
          </Grid>
          <Grid paddingX={5} item xs={12} sm={4} pt={2}>

            {(tier === 2 && video) ? (



              <EFVideoPlayer videoUrl={videoUrl} />
            ) : (
              <EFVideoPlayer videoUrl={defaultVideo} />
            )}
                      {canEdit && (
  
  <EFUploadVideo vendorId={id}/>
  
  )}
          </Grid>
        </Grid>

        {/* Edit Modal */}
        <Modal open={isEditing} onClose={handleClose}>
  <Box
    sx={{
      p: 4,
      bgcolor: '#F2F2F2',
      mx: 'auto',
      my: '10%',
      width: {
        xs: '90%', // 90% width on mobile (extra small screens)
        sm: '80%', // 80% width on small screens and up
        md: '60%', // 60% width on medium screens and up
        lg: '50%', // 50% width on large screens and up
      },
      borderRadius: '8px',
      boxShadow: 24,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Typography variant="h6" mb={2}>
      Edit Vendor Details
    </Typography>
    <Box
      sx={{
        overflowY: 'auto',  // Enables vertical scrolling
        maxHeight: '60vh',  // Adjust the height to your liking
        flex: 1,
      }}
    >
      <TextField
        fullWidth
        label="Name"
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Profile Picture URL"
        value={editedAvi}
        onChange={(e) => setEditedAvi(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Description"
        multiline
        rows={4}
        value={editedDescription}
        onChange={(e) => setEditedDescription(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Tier (for testing only)"
        value={editedTier}
        onChange={(e) => setTier(Number(e.target.value))}
        margin="normal"
      />
      <InputLabel id="demo-simple-select-label">Business Type</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="vendor-type"
        value={editedVendorType}
        label="Vendor Type"
        onChange={handleTypeChange}
      >
        {businessTypeDropdown.map((businessType) => (
          <MenuItem key={businessType} value={businessType}>
            {businessType}
          </MenuItem>
        ))}
      </Select>
      <TextField
        fullWidth
        label="Video URL"
        value={editedVideo}
        onChange={(e) => setEditedVideo(e.target.value)}
        margin="normal"
      />
      <Box mt={2}>
        <UploadPhoto 
          vendorId={id}
          id={randID}
          source="Images" 
          onUploadComplete={(url) => console.log("Uploaded URL:", url)} 
        />
        <Button onClick={handleSave} variant="contained" color="info" sx={{ mr: 2 }}>
          Save
        </Button>
        <Button onClick={handleClose} variant="outlined" color="error" sx={{ color: 'red' }}>
          Cancel
        </Button>
      </Box>
    </Box>
  </Box>
</Modal>

<Snackbar
        open={!!errorMessage}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={8000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
      <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />
      </Grid>
    </>
  );
};

export default Profile;
