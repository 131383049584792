import { Box, Button, CardMedia, Grid, Typography } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../../../firebase';
import BaseLayout from '../../../layouts/sections/components/BaseLayout';

function CommunityEvents() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsRef = collection(firestore, "Events");
        const q = query(eventsRef, where("public", "==", true)); // Fetch only public events
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEvents(eventsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching community events:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleViewDetails = (eventId: string) => {
    const user = auth.currentUser;
    /*
    if (!user) {
      // Redirect to sign-in page if the user is not authenticated
     // navigate('/sign-in');
    } else {
      */
      // Navigate to event details if the user is authenticated
      navigate(`/events/${eventId}`);
    //}
  };

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <BaseLayout
      title="Community Events"
      breadcrumb={[
        { label: "Home", route: "/" },
        { label: "Community Events", route: "/community-events" },
      ]}
    >
  
      <Grid container spacing={3}>
        {events.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event.id}>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: 2,
                boxShadow: 3,
              }}
            >
              {event.event_image ? (
                <CardMedia
                  component="img"
                  alt={event.event_name}
                  height="200"
                  image={event.event_image}
                  sx={{ objectFit: 'cover', borderRadius: 2 }}
                />
              ) : (
                <Box sx={{ height: '200px', backgroundColor: '#f5f5f5', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#aaa' }}>
                  No Image Available
                </Box>
              )}
              <Typography variant="h6" sx={{ marginTop: 2 }}>{event.event_name}</Typography>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {event.event_description.slice(0, 100)}...
              </Typography>
              <Button
                sx={{ marginTop: 2 }}
                onClick={() => handleViewDetails(event.id)}
              >
                View Details
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </BaseLayout>
  );
}

export default CommunityEvents;
