import { Button } from "@mui/material";
import { useState } from "react";
import EFVendorInquiryDialog from "./components/EFVendorInquiryDialog";

const EFVendorInquiryButton = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <>
      <Button className="ef-btn blue" onClick={handleOpenDialog}>
        Click Here To Join Our Vendors
      </Button>

      <EFVendorInquiryDialog open={openDialog} onClose={handleCloseDialog} />
    </>
  );
};

export default EFVendorInquiryButton;
