import * as firebase from 'firebase/app';
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence, signInAnonymously, signInWithEmailAndPassword, signOut } from "firebase/auth";
import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import 'firebase/storage';
import { getStorage, ref } from 'firebase/storage';
import Cookies from 'js-cookie';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional


//  DEV 
///*
const firebaseConfig = {
  apiKey: "AIzaSyAn-Upj0XlPC51K7M6MnQxO4k4Lsi9DFH4",
  authDomain: "eventflow-dev-1ffc9.firebaseapp.com",
  projectId: "eventflow-dev-1ffc9",
  storageBucket: "eventflow-dev-1ffc9.appspot.com",
  messagingSenderId: "220263589159",
  appId: "1:220263589159:web:1b747c6347ea4e96ae3502",
  measurementId: "G-ZEV40LW70Q"
};


const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage();
export const storageRef = ref(storage);
export const functions = getFunctions(app);

// Store the user's authentication information in cookies
const storeUserInCookies = (user) => {
  Cookies.set('user', JSON.stringify({
    uid: user.uid,
    email: user.email || null, 
    isVendor: user.isVendor, 
  }), { expires: 7 });  // Cookie expires in 7 days
};

// Check if a user is in cookies
const getUserFromCookies = () => {
  const userCookie = Cookies.get('user');
  return userCookie ? JSON.parse(userCookie) : null;
};

export const cookiesUser = getUserFromCookies();


// Handle email/password sign-in
export const handleSignIn = async (email, password, rememberMe) => {
  try {
    // Set persistence based on whether user selected "remember me"
    const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
    await setPersistence(auth, persistence);
    
    // Sign in with email and password
    await signInWithEmailAndPassword(auth, email, password);
    
    // Store user information in cookies
    storeUserInCookies(auth.currentUser);

   // alert("Signed in successfully!");
    window.location.href = '/events';  // Redirect to the homepage or other page
  } catch (err) {
    console.error("Error signing in:", err);
    alert("Error signing in: " + err.message);
  }
};

// Handle anonymous sign-in
export const anonSignIn = async () => {
  try {
    await signInAnonymously(auth);
    console.log('User signed in anonymously.');
    
    // Store the anonymous user in cookies
    storeUserInCookies(auth.currentUser);
  } catch (error) {
    console.error('Error signing in anonymously:', error);
  }
};

// Handle sign-out
export const logout = () => {
  signOut(auth).then(() => {
    // Sign-out successful, remove user info from cookies
    Cookies.remove('user');
    console.log('User logged out successfully');
    window.location.href = '/';  // Redirect after logout
  }).catch((error) => {
    console.error('Error logging out:', error);
  });
};

// Checking if the user is already signed in using cookies
export const checkUserSession = () => {
  const user = getUserFromCookies();
  if (user) {
    console.log('User session found:', user);
    return user;  // Return the user data if present
  } else {
    console.log('No user session found in cookies.');
    return null;
  }
};





