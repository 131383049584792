// src/components/EditInventoryDialog.js

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import PropTypes from 'prop-types'; // Import PropTypes
import { useEffect, useState } from 'react';
import { auth, firestore } from '../../../../firebase';

const EditInventory = ({ open, onClose, item, onItemUpdated }) => {
  const [editedItem, setEditedItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (item) {
      setEditedItem({ ...item });
    }
  }, [item]);

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setEditedItem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveItem = async () => {
    try {
      if (!editedItem) return;

      const vendorRef = doc(firestore, 'vendors', auth.currentUser.uid, 'Inventory', editedItem.id);
      await updateDoc(vendorRef, editedItem); // Update the item in Firestore

      setSuccessMessage(`Item "${editedItem.name}" updated successfully`);
      onItemUpdated(); // Notify parent to refresh inventory list
      onClose(); // Close the dialog
    } catch (error) {
      setErrorMessage('Failed to update the item. Please try again.');
      console.error('Error updating inventory item:', error);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Inventory Item</DialogTitle>
        <DialogContent>
          <TextField
            label="Item Name"
            name="name"
            value={editedItem?.name || ''}
            onChange={handleItemChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={editedItem?.description || ''}
            onChange={handleItemChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Price"
            name="price"
            value={editedItem?.price || ''}
            onChange={handleItemChange}
            fullWidth
            margin="normal"
            type="number"
          />
          <TextField
            label="Image URL"
            name="imageUrl"
            value={editedItem?.imageUrl || ''}
            onChange={handleItemChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveItem} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for error and success messages */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={8000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </>
  );
};

// Add PropTypes validation
EditInventory.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  item: PropTypes.object,
  onItemUpdated: PropTypes.func.isRequired,
};

export default EditInventory;
